import * as turf from "@turf/turf";

//find the center for all the images in a boma
export const calculateCenter = (points) => {
  const coordinates = points;
  // Compute Geographic Center
  const center = turf.center(
    turf.featureCollection(coordinates.map((coord) => turf.point(coord)))
  );
  const centerCoords = center.geometry.coordinates;
  // Calculate Radius
  let maxDistance = 0;
  coordinates.forEach((coord) => {
    const dist = turf.distance(turf.point(centerCoords), turf.point(coord), {
      units: "kilometers",
    });
    maxDistance = Math.max(maxDistance, dist);
  });

  return {
    centerArray: [centerCoords[0], centerCoords[1]],
    radius: maxDistance,
  };
};

// create a circle around the images/bomas
export const createCircle = (center, radiusInKm, numPoints = 64) => {
  const coords = [];
  const earthRadius = 6371;

  for (let i = 0; i < numPoints; i++) {
    const angle = (i / numPoints) * (2 * Math.PI);
    const dx = radiusInKm * Math.cos(angle);
    const dy = radiusInKm * Math.sin(angle);

    const latitude = center[1] + (dy / earthRadius) * (180 / Math.PI);
    const longitude =
      center[0] +
      (dx / (earthRadius * Math.cos((center[1] * Math.PI) / 180))) *
        (180 / Math.PI);

    coords.push([longitude, latitude]);
  }
  coords.push(coords[0]);
  return coords;
};

//calculate bounds to foucs the view
export const calculateBounds = (coordinates) => {
  let minLat = Infinity,
    minLon = Infinity;
  let maxLat = -Infinity,
    maxLon = -Infinity;

  coordinates.forEach(([lon, lat]) => {
    minLat = Math.min(minLat, lat);
    minLon = Math.min(minLon, lon);
    maxLat = Math.max(maxLat, lat);
    maxLon = Math.max(maxLon, lon);
  });

  return [
    [minLon, minLat],
    [maxLon, maxLat],
  ];
};

//extract lat and long from the feature collection
export const extractLatLong = (geojson) => {
  // Check if the input is a FeatureCollection
  if (geojson.type !== "FeatureCollection") {
    throw new Error("Invalid GeoJSON: Not a FeatureCollection");
  }
  const coordinates = [];
  geojson.features.forEach((feature) => {
    // Check if the feature's geometry is of type Point
    if (feature.geometry.type === "Point") {
      // Extract coordinates (longitude, latitude)
      const [longitude, latitude] = feature.geometry.coordinates;
      coordinates.push([longitude, latitude]);
    }
  });

  return coordinates;
};
