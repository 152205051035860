import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";


import {
  setShowDeleteBomaModal,
  setBomaIdToBeDeleted,
  setBomaDeleted,
  setAnalysisJobList,
} from "../../store/slices/globalSlice.js";
import { toast } from "react-toastify";

const DeleteBomaModel = () => {
  const dispatch = useDispatch();
  const bomaIdToBeDeleted = useSelector(
    (state) => state.global.bomaIdToBeDeleted
  );


  const analysisJobList = useSelector((state) => state.global.analysisJobList); //list of jobs
  


  const BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;
  const accessToken = sessionStorage.getItem("accessToken");
  const [isLoading, setIsloading] = useState(false);

  async function handleBomaDeletion() {
    //make an api call to delete the boma
    setIsloading(true);
    try {
      await fetch(
        `${BASE_URL}collections/${bomaIdToBeDeleted}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Boma deletion successful");
      //update the analysis list if that collection exist in the list remove it too
      const updatedList = analysisJobList.filter(item => item!==bomaIdToBeDeleted);
      setAnalysisJobList(updatedList)

    } catch (error) {
      toast.error("Error deleting boma");
    } finally {
      setIsloading(false);
      dispatch(setShowDeleteBomaModal(false));
      dispatch(setBomaIdToBeDeleted(null));

      dispatch(setBomaDeleted());
    }
  }

  function handleCancelButton() {
    dispatch(setShowDeleteBomaModal(false));
  }

  if (!bomaIdToBeDeleted) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
      <div
        className="bg-gray-800 p-8 rounded-md text-center shadow-lg border border-gray-600"
        style={{ background: "#1E1E20" }}
      >
        <h2 className="text-2xl font-bold text-white mb-4">
          Kindly confirm to delete the boma {bomaIdToBeDeleted}
        </h2>

        <div className="flex">
          <button
            onClick={handleCancelButton}
            className="block w-full p-2 mr-3 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 transition duration-300 "
          >
            Cancel
          </button>
          <button
            onClick={handleBomaDeletion}
            className="block w-full p-2 bg-red-600 text-white rounded-md shadow-md hover:bg-red-700 transition duration-300 "
          >
            {isLoading? "Deleting ..." : "Delete"}
          </button>
        </div>
      </div>
    </div>
  );
};


export default DeleteBomaModel;
