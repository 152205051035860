import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  
  Legend,
  Filler
} from "chart.js";
import zoomIcon from "../assets/icons/zoomIcon.png";
import { processTimelineData } from "../utils/graph";


// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,

  Legend,
  Filler
);


const TimeLineChart = ({ data: incomingTimelineData, handleZoom, highlightCollectionId }) => {
  const { dates, seriesData } = processTimelineData(incomingTimelineData);

  // //console.log("SMALL GRAPH DATA IN REVIEW TAB", incomingTimelineData)
  //console.log("SMALL GRAPH DATA IN REVIEW TAB", seriesData)


  // Transform data for chart
  const labels = seriesData.map(item => item.date); // Date for x-axis (strings)
  const dataPoints = seriesData.map(item => parseFloat(item.y)); // Convert 'y' to number
  const collectionIds = seriesData.map(item => item.collection_id); // Store collection IDs

  // Highlight specific point based on `highlightCollectionId` prop
  const highlightIndex = collectionIds.indexOf(highlightCollectionId);
  const highlightPoint = highlightIndex !== -1 ? {
    x: seriesData[highlightIndex].date,
    y: seriesData[highlightIndex].y,
    collection_id: seriesData[highlightIndex].collection_id,
  } : null;

  // Chart.js data structure
  const data = {
    labels: labels,
    datasets: [
      {
        label: null,
        data: dataPoints,
        fill: false,
        borderColor: 'rgba(67, 61, 239, 0.6)',
        tension: 0.1,
        pointRadius: (context) => {
          // If the current point matches the highlight, return larger radius for emphasis
          return highlightPoint && context.dataIndex === highlightIndex ? 5 : 5;
        },
        pointBackgroundColor: (context) => {
          // Highlight the point with a different color
          return highlightPoint && context.dataIndex === highlightIndex ? "rgba(255, 99, 132, 0.6)" : "rgba(191, 235, 90, 0.94)";
        },
        // pointBackgroundColor: 'rgba(255, 99, 132, 0.6)', // Add point color
        pointBorderWidth: 1, // Adjust border width
        hoverRadius: 12, // Increase radius on hover
        hoverBorderWidth: 2, // Increase border width on hover
      },
    ],
  };


  // Options to customize tooltip and x-axis
  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'category', // Use category scale to display string labels on x-axis
        ticks: {
          color: '#ffffff', // Set text color for x-axis ticks
          font: {
            size: 12, // Set font size for x-axis ticks
          },
        },
        grid: {
          display: false, // Remove grid lines for x-axis
        },
      },
      y: {
        ticks: {
          color: '#ffffff', // Set text color for y-axis ticks
          font: {
            size: 12, // Set font size for y-axis ticks
          },
        },
        grid: {
          display: false, // Remove grid lines for x-axis
        },
      },
    },
    plugins: {
      tooltip: false,
      legend: {
        display: false, // Disable the legend
      },
    },
    
    
  };

  const handleImageClick = () => {
    handleZoom(true);
  };

  return (
    <div className="p-3">
      <img
        src={zoomIcon}
        alt="zoom"
        className="absolute z-10 top-3 right-3 w-8 cursor-pointer" 
        onClick={handleImageClick}
      />
      <Line data={data} options={options} />

    </div>
  );
};

export default TimeLineChart;
