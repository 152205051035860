//extract date from date time string
function extractDate(dateString) {
  const parsedDate = new Date(dateString);

  if (isNaN(parsedDate)) {
    throw new Error("Invalid date string");
  }

  // Format the date in a readable format (YYYY-MM-DD)
  const year = parsedDate.getUTCFullYear();
  const month = String(parsedDate.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(parsedDate.getUTCDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

//group the boma collection by date
export function groupGeoJSONByDate(geoJSONData) {
  // Step 1: Group features by the date in properties
  const groupedFeatures = geoJSONData.features.reduce((acc, feature) => {
    const date = extractDate(feature.properties.timeCreated);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(feature);
    return acc;
  }, {});

  // Step 2: Convert grouped features into separate FeatureCollection objects
  const geoJSONByDate = Object.entries(groupedFeatures).map(
    ([date, features]) => ({
      type: "FeatureCollection",
      crs: geoJSONData.crs, 
      date: date, 
      features: features,
    })
  );

  return geoJSONByDate;
}

//parse json comming from the store
export function parseJSONWithCustomDates(jsonString) {
  const data = JSON.parse(jsonString);

  // Recursive function to walk through the JSON object and convert date strings
  function traverseAndConvertDates(obj) {
    for (const key in obj) {
      if (
        typeof obj[key] === "string" &&
        /^\d{4}:\d{2}:\d{2} \d{2}:\d{2}:\d{2}$/.test(obj[key])
      ) {
        obj[key] = extractDate(obj[key]);
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        traverseAndConvertDates(obj[key]);
      }
    }
  }

  traverseAndConvertDates(data);
  return data;
}

// function to validate email
export function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!email) {
      throw {email : "Email is required."};
  }
  if (!emailRegex.test(email)) {
      throw {email: "Invalid email format."};
  }
}

// Function to validate password
export function validatePassword(password) {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%_]{6,64}$/;

  if (!password) {
      throw {password: "Password is required."};
  }
  if (!passwordRegex.test(password)) {
      throw {  password:
        "Password must be 6-64 characters long, include uppercase and lowercase letters, a digit, and can contain special characters !@#$%_."
      }
      ;
  }
}

// Combined Registration Validation
export function validateRegistration(email, password) {
  try {
      validateEmail(email);
      validatePassword(password);
      ////console.log("Registration validated!");
  } catch (error) {
      ////console.log("Validation Error:", error);
      throw error; // Re-throw the error to propagate it further if needed
  }
}