import React, { useState } from "react";
import uploadIcon from "../../assets/icons/upload.png";
import { useDispatch, useSelector } from "react-redux";
import { setAnalysisJobList } from "../../store/slices/globalSlice";

import {
  setIsUploading,
  setSelectedFiles,
  setTotalFiles,
  setCurrentFileUpload,
  setCollectionId,
  setUploadStatus,
  setSuccessfulUploads,
  addFailedUploads,
  setShowUploadModal,

  //Files
  setFailedUploads,
  // clearFailedUploads,
  setUploadSuccessful,
  clearUploadStatus,
  setBomaCreated,
} from "../../store/slices/filesUploadSlice";

import { toast } from "react-toastify";
import axios from "axios";
import { Dot } from "react-animated-dots";

const BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;

const SERVER_URL = `${BASE_URL}images/blob`;
const COLLECTION_URL = `${BASE_URL}collections`;

const UploadImage = () => {
  const dispatch = useDispatch();

  const isUploading = useSelector((state) => state.upload.isUploading);
  const selectedFiles = useSelector((state) => state.upload.selectedFiles);
  const successfulUploads = useSelector((state) => state.upload.successfulUploads);
  const failedUploads = useSelector((state) => state.upload.failedUploads);
  const totalFiles = useSelector((state) => state.upload.totalFiles);
  const uploadStatus = useSelector((state) => state.upload.uploadStatus);
  const modalVisible = useSelector((state) => state.upload.showUploadModal);
  const currentFailedUploads = useSelector((state) => state.upload.failedUploads);
  const previousSuccessfulUploads = useSelector((state) => state.upload.successfulUploads);
  let collectionId = useSelector((state) => state.upload.collectionId);

  const uploadSuccessful = useSelector((state) => state.upload.uploadSuccessful);

  // const analysisJobList = useSelector((state) => state.global.analysisJobList); //list of jobs
  // const totalJobs = analysisJobList.length;

  const currentFileUpload = useSelector((state) => state.upload.currentFileUpload);

  //HANDLE FILE SELECTION
  const handleFileSelection = (event) => {
    const files = Array.from(event.target.files);
    dispatch(setSelectedFiles(files));
    dispatch(setTotalFiles(files.length));
  };

  // Upload a single file
  const uploadFile = async (file) => {
    const token = sessionStorage.getItem("accessToken");

    if(file.name){
      dispatch(setCurrentFileUpload(file.name));
      console.log("FILE NAME ", file.name);
    }else{
      dispatch(setCurrentFileUpload(file.file.name));
      console.log("FILE NAME ", file.file.name);
    }

    // dispatch(setCurrentFileUpload(file.name));
    
    const formData = new FormData();
    formData.append("file", file);
    formData.append("collection_id", collectionId);

    try {
      const response = await axios.post(SERVER_URL, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return { name: file.name, status: "success", data: response.data };
    } catch (error) {
      console.log("Error in file upload", error)
      const errorObj = error.response.data.errors;
      const errorKey = Object.keys(errorObj)[0];
      const errorMsg = errorObj[errorKey];
      return { name: file.name, status: "failed", error: errorMsg };
    }
  };

  //move to utils file
  function getCustomTimezoneString() {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Get timezone abbreviation
    const timeZone = Intl.DateTimeFormat("en", { timeZoneName: "short" })
      .formatToParts(date)
      .find((part) => part.type === "timeZoneName").value;

    return `${year}${month}${day}-${hours}${minutes}${seconds}-${timeZone}`;
  }

  //HANDLE FILE UPLOAD
  const handleUpload = async () => {
    if (selectedFiles.length === 0 || isUploading) return;

    dispatch(setIsUploading(true));
    let successCount = 0;

    // CREATE COLLECTION
    if (collectionId == null) {
      try {
        const name = getCustomTimezoneString();
        const token = sessionStorage.getItem("accessToken");

        const response = await axios.post(
          COLLECTION_URL,
          {
            name: name,
            access: "private",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        collectionId = response.data.id;
        dispatch(setCollectionId(response.data.id));
      } catch (error) {
        //close the upload dialog and set is uploading to false
        dispatch(setIsUploading(false));
        toast.error("Error creating collection");
        return;
      }
    }

    for (let i = 0; i < selectedFiles.length; i++) {
      const item = selectedFiles[i];
      ////console.log("UPLOADING ", item.name);

      try {
        const result = await uploadFile(item); // Wait for the upload to complete
        dispatch(setUploadStatus(result));
        ////console.log("uploading result ", result);

        if (result.status === "success") {
          ////console.log("SUCCESS", item.name);
          successCount++;
          dispatch(setSuccessfulUploads(successCount));
        } else {
          ////console.log("FAILED", item.name);
          dispatch(addFailedUploads({ file: item, error: result.error }));
        }
      } catch (error) {
        //console.error("Error uploading file:", item.name, error);
        // dispatch(addFailedUploads(item));
      }
    }

    dispatch(setCurrentFileUpload(""));
    
  };

  //RETRY ALL FAILED UPLOADS
  const retryAll = async () => {
    for (const file of failedUploads) {
      await retryUpload(file);
    }
  };

  //RETRY FAILED UPLOAD
  const retryUpload = async (file) => {



    const result = await uploadFile(file);

    if (result.status === "success") {
      dispatch(setSuccessfulUploads(previousSuccessfulUploads + 1));
      dispatch(setUploadStatus(result));
      dispatch(
        setFailedUploads(
          currentFailedUploads.filter((f) => f.name !== file.name)
        )
      );
    } else {
      dispatch(setCurrentFileUpload(null));
    }
  };

  //DELETE FILE
  const deleteFailedUpload = (file) => {
    //check if there is only one file in the selected files
    if (selectedFiles.length === 1) {
      ////console.log("ONLY ONE FILE IS THERE TO DELETE");
      deleteAllFailedUploads();
      dispatch(setCollectionId(null));

    } else {
      dispatch(
        setFailedUploads(failedUploads.filter((f) => f.file.name !== file.name))
      );
      dispatch(
        setSelectedFiles(selectedFiles.filter((f) => f.name !== file.name))
      );
      dispatch(setTotalFiles(totalFiles - 1));
    }
  };

  //DELETE ALL FAILED UPLOADS
  const deleteAllFailedUploads = () => {
    ////console.log("DELETE ALL THE FILES");
    dispatch(setBomaCreated());
    dispatch(setCollectionId(null));


    // dispatch(clearFailedUploads());
    // const updatedSelectedFiles = selectedFiles.filter((file) =>
    //   uploadStatus.some(
    //     (status) => status.name === file.name && status.status === "success"
    //   )
    // );
    // dispatch(setSelectedFiles(updatedSelectedFiles));
    // dispatch(setTotalFiles(updatedSelectedFiles.length));
  };

  //HIDE UPLOAD MODAL
  const closeModal = () => {
    if (!isUploading) {
      dispatch(setBomaCreated());
      dispatch(setCollectionId(null));

    }

    if (uploadSuccessful === true) {
      dispatch(setBomaCreated());
      // dispatch(setCollectionId(null));

    }

    ////console.log("CLOASE UPLOAD MDEL   ");
    dispatch(setShowUploadModal(false));
    if (uploadSuccessful) {
      dispatch(setIsUploading(false));
    }

    if (
      successfulUploads === totalFiles &&
      totalFiles !== 0 &&
      uploadSuccessful === true
    ) {
      dispatch(setSelectedFiles([]));
      dispatch(clearUploadStatus());
      dispatch(setSuccessfulUploads(0));
      dispatch(setFailedUploads([]));
      dispatch(setTotalFiles(0));

      dispatch(setUploadSuccessful(false));
      dispatch(setCollectionId(null));

    }
  };

  //HANDLE ANALYSIS
  const handleAnalysis = async () => {
    //make api call to run the analysis on the particular boma
    ////console.log("ANALYSIS STARTED");
    dispatch(setIsUploading(false));
    // dispatch(setIsAnalysing(true));
    dispatch(setShowUploadModal(false));

    try {
      const token = sessionStorage.getItem("accessToken");
      //console.log("access token ", token);
      const response = await axios.post(
        `${BASE_URL}analytics/collections/${collectionId}`,
        {
          module: process.env.REACT_APP_ANALYSIS_MODULE,
          apiurl: process.env.REACT_APP_ANALYSIS_API_URL,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      ////console.log("ANALYTICS RESPONSE", response);
      dispatch(setAnalysisJobList(collectionId));
    } catch (error) {
      //console.error("Error in analysis", error);
    }

    dispatch(setBomaCreated());
    dispatch(setCollectionId(null));


    // dispatch(setSelectedFiles([]));
    // dispatch(clearUploadStatus());
    // dispatch(setSuccessfulUploads(0));
    // dispatch(setFailedUploads([]));
    // dispatch(setTotalFiles(0));
  };

  return modalVisible ? (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
      <div
        className="relative w-full max-w-lg mx-auto bg-gray-900 text-white shadow-lg  overflow-hidden border border-gray-700"
        style={{ background: "#1E1E20" }}
      >
        {/* Modal Header */}

        <div className="p-4 flex justify-between items-center border-b border-gray-700">
          <h2 className="text-lg font-semibold">
            Upload Files {collectionId ? `to Collection ${collectionId} ` : " "}
            {isUploading && (
              <>
                <Dot>.</Dot>
                <Dot>.</Dot>
                <Dot>.</Dot>
                <Dot>.</Dot>
              </>
            )}
          </h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            onClick={closeModal} // Hide the modal when clicked
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>

        <div className="p-2">
          {/* SELECT IMAGES TO UPLOAD */}
          {!isUploading ? (
            // File Selection View
            <div className="p-6 rounded-lg shadow-lg max-w-md mx-auto">
              {/* File Input */}

              {totalFiles === 0 ? (
                <div
                  className="relative w-full h-48 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center bg-cover bg-center bg-no-repeat"
                  style={{
                    backgroundImage: `url(${uploadIcon})`, // Placeholder image as background
                    opacity: 0.7,
                  }}
                >
                  <input
                    type="file"
                    multiple
                    onChange={handleFileSelection}
                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                  />
                  <div className="flex flex-col items-center justify-center pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-10 w-10 text-blue-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      opacity={0.5}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 16l4 4m0 0l4-4m-4 4V4m13 8h-5m0 0l4-4m-4 4l4 4"
                      />
                    </svg>
                    <span className="mt-2 text-lg text-white">
                      Click to upload files
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  {selectedFiles.map((file) => (
                    <div>
                      <p>{file.name}</p>
                    </div>
                  ))}
                </div>
              )}

              {/* Upload Button */}
              <button
                onClick={handleUpload}
                className={`mt-4 px-4 py-2 text-white font-semibold rounded w-full transition duration-300 ${
                  selectedFiles.length === 0
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700"
                }`}
                disabled={selectedFiles.length === 0}
              >
                Upload
              </button>
            </div>
          ) : (
            // Upload Status View
            <div>
              <p className="text-sm mb-2">
                {successfulUploads > 0
                  ? Math.round((successfulUploads / totalFiles) * 100)
                  : "0"}
                % uploaded
              </p>
              <div className="w-full bg-gray-700 rounded-full h-2.5">
                <div
                  className="bg-green-500 h-2.5 rounded-full"
                  style={{
                    width: `${(successfulUploads / totalFiles) * 100}%`,
                  }}
                ></div>
              </div>

              {/* File List */}
              <div className="h-48 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-900 mt-2">
                {selectedFiles.map((file, index) => (
                  <div key={index} className="py-1 flex items-center">
                    <div
                      className={`w-2 h-2 ${
                        uploadStatus.some(
                          (status) =>
                            status.name === file.name &&
                            status.status === "success"
                        )
                          ? "bg-green-500"
                          : failedUploads.includes(file)
                          ? "bg-yellow-600"
                          : "bg-blue-500"
                      } rounded-full mr-2 flex-shrink-0`}
                    />
                    <span className="text-sm truncate">
                      {file.name}
                      {currentFileUpload === file.name && (
                        <span className="text-green-500 ml-4">
                          {" "}
                          Uploading
                          <Dot>.</Dot>
                          <Dot>.</Dot>
                          <Dot>.</Dot>
                        </span>
                      )}
                    </span>
                  </div>
                ))}
              </div>

              {/* Retry/ Delete for Failed Uploads */}
              {failedUploads.length > 0 && (
                <div className="mt-4 border-t border-gray-700 pt-4">
                  <div className="bg-transparent px-4 py-3 rounded mb-2 text-sm">
                    There was a problem with these images. Solve them to enable
                    analysis.
                  </div>

                  <div className="flex justify-end mb-2">
                    <button
                      onClick={retryAll}
                      className="text-blue-400 hover:text-blue-300 text-sm mr-2"
                    >
                      Retry all
                    </button>
                    <button
                      onClick={deleteAllFailedUploads}
                      className="text-red-400 hover:text-red-300 text-sm"
                    >
                      Delete all
                    </button>
                  </div>

                  <div className="h-20 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-900">
                    {failedUploads.map((file, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center py-2 border-b-4 border-yellow-400"
                      >
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 text-yellow-600 mr-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                          </svg>
                          <div>
                            <p className="text-sm truncate">{file.file.name}</p>
                            <p className="text-red-400 text-sm">{file.error}</p>
                          </div>
                        </div>
                        <div>
                          <button
                            onClick={() => retryUpload(file.file)}
                            className="text-blue-400 hover:text-blue-300 text-sm mr-2"
                          >
                            Retry
                          </button>
                          <button
                            onClick={() => deleteFailedUpload(file.file)}
                            className="text-red-400 hover:text-red-300 text-sm"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Footer Buttons */}
              <div className="p-4 flex justify-between border-t border-gray-700">
                <button
                  className="px-4 py-2 border border-gray-600 text-gray-300 rounded hover:bg-gray-800 transition duration-300"
                  onClick={closeModal}
                >
                  Close
                </button>
                {/* <button
                  className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300"
                  onClick={handleAnalysis}
                >
                  Run analysis
                </button> */}

                <button
                  className={`px-4 py-2 rounded transition duration-300 ${
                    totalFiles === successfulUploads && totalFiles !== 0
                      ? "bg-blue-600 text-white hover:bg-blue-700"
                      : "bg-gray-400 text-gray-200 cursor-not-allowed"
                  }`}
                  onClick={handleAnalysis}
                  disabled={
                    !(totalFiles === successfulUploads && totalFiles !== 0)
                  }
                >
                  Analyze
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default UploadImage;
