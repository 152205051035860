import { useEffect } from "react";
import axios from "axios";
import { setAnalysisJobList, setJobStatusList } from "../store/slices/globalSlice.js";

const usePolling = (analysisJobList, jobStatusList, dispatch, interval = 15000) => {
  useEffect(() => {
    if (analysisJobList.length === 0) return;

    const BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;
    const accessToken = sessionStorage.getItem("accessToken");

    const intervalId = setInterval(async () => {
        try {
          //console.log("MAKING API CALL FOR POLLING")
        // Fetch updated job statuses
        const response = await axios.get(`${BASE_URL}analytics/collections`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        const data = response.data;

        // Process the fetched data
        let pendingListOfJobs = [];
        data.forEach((item) => {
          const bomaId = item.id;
          pendingListOfJobs.push(bomaId);
        });

        const completedBomaJobs = analysisJobList.filter(
          (item) => !pendingListOfJobs.includes(item)
        );

        const updatedJobStatus = { ...jobStatusList };
        completedBomaJobs.forEach((item) => {
          updatedJobStatus[item] = "completed";
        });

        // Dispatch updates if there are changes
        if (pendingListOfJobs.length !== analysisJobList.length) {
          dispatch(setAnalysisJobList(pendingListOfJobs));
          dispatch(setJobStatusList(updatedJobStatus));
        }
      } catch (error) {
        console.error("Failed to fetch job status:", error);
      }

      // Stop polling if no jobs remain
      if (analysisJobList.length === 0) {
        clearInterval(intervalId);
      }
    }, interval);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [analysisJobList, jobStatusList, dispatch, interval]);
};

export default usePolling;
