import { useEffect , useCallback} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setBomaReviewDetails,
  setIsReviewing,
  setIsSidebarOpen,
  setShowBomaCluster,
  setBomaPopupDetails,
  setBomaClusterDetails,
  setHighlightedImageDetails,
  setImageReviewDetails,
} from "../../../store/slices/globalSlice.js";
import { createPopup, extractDateAndDay } from "../../../utils/createPopup.js";

const BaseLayer = ({ mapRef }) => {
  const dispatch = useDispatch();

    const checkedCollectionList = useSelector((state) => state.mapLayer.checkedCollectionList);
    const userBomaData = useSelector((state) => state.global.userBomaData)

  // Redux selectors
  const showBomaCluster = useSelector((state) => state.global.showBomaCluster);
  const isReviewing = useSelector((state) => state.global.isReviewing);
  const filteredData = useSelector((state) => state.global.filteredData);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const bomaPopupDetails = useSelector((state) => state.global.bomaPopupDetails);

  // Function to clear layers and sources
  const clearLayersAndSources = () => {
    if (mapRef.current && mapRef.current.isStyleLoaded()) {
      ["clusters", "cluster-count", "unclustered-point"].forEach((layer) => {
        if (mapRef.current.getLayer(layer)) mapRef.current.removeLayer(layer);
      });
      if (mapRef.current.getSource("earthquakes")) {
        mapRef.current.removeSource("earthquakes");
      }
    } else {
      //console.log("Map instance not ready or unloaded; skipping cleanup.");
    }
  };

  // Function to add source and layers for the base layer
  const addBaseLayer = useCallback((collectionData) => {
    const data = collectionData || filteredData;
    if (!mapRef.current || !data) return;
    
    if(mapRef.current.getSource("earthquakes")){
      mapRef.current.getSource("earthquakes").setData(data);
      return
    }

    
    mapRef.current.addSource("earthquakes", {
      type: "geojson",
      data: data,
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    });
  
    mapRef.current.addLayer({
      id: "clusters",
      type: "circle",
      source: "earthquakes",
      filter: ["has", "point_count"],
      paint: {
        "circle-color": [
          "step",
          ["get", "point_count"],
          "#51bbd6",
          100,
          "#f1f075",
          750,
          "#f28cb1",
        ],
        "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
      },
    });
  
    mapRef.current.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "earthquakes",
      filter: ["has", "point_count"],
      layout: {
        "text-field": ["get", "point_count_abbreviated"],
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12,
      },
    });
  
    mapRef.current.addLayer({
      id: "unclustered-point",
      type: "circle",
      source: "earthquakes",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-color": "#11b4da",
        "circle-radius": 8,
        "circle-stroke-width": 1,
        "circle-stroke-color": "#fff",
      },
    });
  }, [mapRef, filteredData]); // Dependencies array ensures this function is only recreated when these change.
  

  // Add popup interaction for `bomaPopupDetails`
  const handleBomaPopup = () => {
    if (bomaPopupDetails) {
      ////console.log(bomaPopupDetails)
      const featureDetails = {
        id: bomaPopupDetails.properties?.id,
        region: bomaPopupDetails.properties?.region,
        datetime: bomaPopupDetails.properties?.timeCreated,
      };

      const coordinates = {
        latitude: bomaPopupDetails.geometry?.coordinates[1],
        longitude: bomaPopupDetails.geometry?.coordinates[0],
      };

      createPopup({
        mapRef: mapRef.current,
        coordinates,
        featureDetails,
        isLoggedIn,
        onReviewClick: () => {
          dispatch(setBomaReviewDetails(JSON.stringify(bomaPopupDetails)));
          //console.log("BOMA DETAILS IN BASE LAYER",JSON.parse(bomaPopupDetails.properties.imagesGeojson).features[0])

          dispatch(setIsReviewing(true));
          dispatch(setIsSidebarOpen(true));
          dispatch(setShowBomaCluster(false));


          //TODO - fix the sidebar and image highlighting using the same variable
          dispatch(setImageReviewDetails(JSON.parse(bomaPopupDetails.properties.imagesGeojson).features[0]))
          dispatch(setHighlightedImageDetails(JSON.parse(bomaPopupDetails.properties.imagesGeojson).features[0]))
          
          // Remove any existing popups
          const popups = document.getElementsByClassName("mapboxgl-popup");
          if (popups.length) popups[0].remove();
        },
      });
    }
  };

  // Handle cluster interactions
  const handleClusterInteractions = () => {
    mapRef.current.on("click", "clusters", (e) => {
      const features = mapRef.current.queryRenderedFeatures(e.point, {
        layers: ["clusters"],
      });
      const clusterId = features[0].properties.cluster_id;

      mapRef.current.getSource("earthquakes").getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;
        mapRef.current.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoom,
        });
      });
    });

    mapRef.current.on("click", "unclustered-point", (entryPoint) => {
      const featureData = entryPoint.features[0];
      const bomaClusterInputData = {
        geometry: featureData.geometry,
        properties: featureData.properties,
      };

      dispatch(setBomaPopupDetails(bomaClusterInputData));
      dispatch(setBomaClusterDetails(JSON.stringify(featureData)));
      dispatch(setBomaReviewDetails(JSON.stringify(featureData)));
    });
  };

  useEffect(() => {
    if (!mapRef?.current || !mapRef.current.isStyleLoaded() || checkedCollectionList.length>0) return;

    if (isReviewing || showBomaCluster) {
      clearLayersAndSources();
      return;
    }

    //console.log("reviewing base layer")

    clearLayersAndSources();
    addBaseLayer();
    handleClusterInteractions();
    handleBomaPopup();

    return () => clearLayersAndSources();
  }, [
    filteredData,
    showBomaCluster,
    isReviewing,
    isLoggedIn,
    dispatch,
    mapRef,
    bomaPopupDetails,
    checkedCollectionList,
  ]);



  
  useEffect(() => {

    const filteredBomaData = userBomaData.features.filter(boma =>
      checkedCollectionList.some(item => item.id === boma.properties.id)
    );
    //console.log("FILTERED BOMA DATA ", filteredBomaData);

    //console.log(userBomaData);
    if(checkedCollectionList.length>0){

    const updatedBomaData = {
      crs: userBomaData.crs,
      type: userBomaData.type,
      features: filteredBomaData,
    };


    addBaseLayer(updatedBomaData);


    }
  
  }, [checkedCollectionList, addBaseLayer,userBomaData]);





  if (checkedCollectionList.length > 0) {
    //console.log("RENDERING CHECKED COLLECTION BOMA", checkedCollectionList);
    return (
      <div
        className="fixed top-36 right-4 z-50 bg-gray-800 text-white shadow-lg rounded-lg p-4 w-64 max-h-96 border border-gray-700"
        // style={{
        //   border: "1px solid #4A5568", // A subtle gray border to match the theme
        //   boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // Slight shadow for elevation
        // }}
      >
        <h3 className="font-bold text-lg mb-3 border-b border-gray-600 pb-2">
          {checkedCollectionList.length} Boma Selected
        </h3>

      <div className="max-h-80 overflow-y-auto">


        <ul>
          {checkedCollectionList.map((item, idx) => (
            <li
              key={idx}
              className="mb-3 bg-gray-700 p-3 rounded-lg  transition"
            >
              <p className="text-sm">
                <strong>Collection Id:</strong> {item.id}
              </p>
              <p className="text-sm">
                <strong>Date:</strong> {extractDateAndDay(item.timeCreated)}
              </p>
            </li>
          ))}
        </ul>
      </div>

      </div>
    );
  }
  
  




  return null; // This component manages map layers and interactions





};

export default BaseLayer;
