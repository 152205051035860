import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice.js";
import globalReducer from "./slices/globalSlice.js";
import filesUploadReducer from "./slices/filesUploadSlice.js";
import mapLayerReducer from "./slices/mapLayersSlice.js"

// Combine all slice reducers
const appReducer = combineReducers({
    auth: authReducer,
    global: globalReducer,
    upload: filesUploadReducer,
    mapLayer : mapLayerReducer,
});

// Root reducer to handle RESET action
const rootReducer = (state, action) => {
    if (action.type === 'RESET') {
        state = undefined; // Reset all state to initial values
    }
    return appReducer(state, action);
};

// Configure store with rootReducer
const store = configureStore({
    reducer: rootReducer,
});

export const resetStore = () => ({ type: 'RESET' }); // Action creator for RESET

export default store;

