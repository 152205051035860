import axios from "axios";
import { validateRegistration } from "./helperFun.js";

export class AuthService {
  async makeRequest(
    endpoint,
    method = "POST",
    data = {},
    requiresAuth = false
  ) {
    const url = `${process.env.REACT_APP_AUTH_BASE_URL}${endpoint}`;
    const headers = {};

    if (requiresAuth) {
      const token = sessionStorage.getItem("accessToken");
      if (!token) throw { token: "Unauthorized: No token found" };
      headers.Authorization = `Bearer ${token}`;
    }

    try {
      const response = await axios({ url, method, data, headers });
      return response.data;
    } catch (error) {
      ////console.log(error)
      if (error.response) {
        throw error.response.data.errors;
      } else {
        throw { unexpected: "Something went wrong, try again later" };
      }
    }
  }

  storeTokens({ accessToken, refreshToken }) {
    sessionStorage.setItem("accessToken", accessToken);
    sessionStorage.setItem("refreshToken", refreshToken);
  }

  clearTokens() {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
  }

  async createAccount({ email, password, username, confirmPassword }) {
    validateRegistration(email, password);
    if (password !== confirmPassword) {
      throw { password: "Passwords do not match." };
    }
    const response = await this.makeRequest("auth/register", "POST", {
      email,
      username,
      password,
    });
    return response;
  }

  async login({ username, password }) {
    const response = await this.makeRequest("auth/login", "POST", {
      user: username,
      password,
    });
    this.storeTokens({
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
    });
    return response;
  }

  async logout() {
    const response = await this.makeRequest("auth/logout", "GET", {}, true);
    this.clearTokens();
    return response;
  }
}

const authService = new AuthService();
export default authService;
