import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/authComponents/Button.jsx";
import Input from "../components/authComponents/Input.jsx";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import authService from "../utils/auth.js";
import { toast } from "react-toastify";
import { login as authLogin } from "../store/slices/authSlice.js";

import userIcon from "../assets/icons/username.png";
import lockIcon from "../assets/icons/lock.png";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const login = async (data) => {
    setError("");
    try {
      setIsLoading(true);
      const userData = await authService.login(data);
      if (userData) {
        if (userData.access_token && userData.refresh_token) {
          dispatch(authLogin(userData));
          toast.success("Login Successful");
          //store username in store
          // dispatch(setUserName(data.username));




          //make request to set the user_name
          const URL = `${process.env.REACT_APP_AUTH_BASE_URL}auth/session`;
          const accessToken = sessionStorage.getItem("accessToken");
      
          try {
      
            const response = await fetch(`${URL}`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });

            // await response.json();
            const response_data = await response.json();

            sessionStorage.setItem("username", await response_data.name);

          } catch (error) {
            console.error("There was an error with the request:", error);
            sessionStorage.setItem("username", data.username);
          } 

          navigate("/app");
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      const [type, message] = Object.entries(error)[0];

      setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen"
      style={{ background: "#1E1E20" }}
    >
      <div
        className="w-full max-w-md bg-gray-800 p-10 rounded-xl  transform "
        style={{ background: "#1E1E20" }}
      >
        <h2 className="text-center text-3xl font-bold text-gray-200 mb-6">
          EyeClimate Inc
        </h2>
        <h3 className="text-center text-2xl text-gray-200 mb-6">Log in</h3>

        {error && <p className="text-red-500 text-center mb-6">{error}</p>}

        <form onSubmit={handleSubmit(login)} className="space-y-6">
          <div className="space-y-5">
            <div className="space-y-1">
              <label className="block text-gray-300">Username</label>
              <div className="relative">
                <img
                  src={userIcon}
                  alt="user icon"
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5"
                />
                <Input
                  placeholder="Username or Email"
                  type="text"
                  {...register("username", { required: true })}
                  className="block w-full p-3 pl-12 bg-black text-white border border-gray-600 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:bg-gray-700 focus:border-transparent"
                />
              </div>
            </div>

            <div className="space-y-1">
              <label className="block text-gray-300">Password</label>
              <div className="relative">
                <img
                  src={lockIcon}
                  alt="password icon"
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5"
                />
                <Input
                  placeholder="Password"
                  type="password"
                  {...register("password", { required: true })}
                  className="block w-full p-3 pl-12 bg-black text-gray-200 border border-gray-600 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:bg-gray-700 focus:border-transparent"
                />
              </div>
            </div>
            <p
              className="text-blue-500 cursor-pointer"
              onClick={() => navigate("/forgotPassword")}
            >
              Did you forget your password?
            </p>

            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                style={{
                  height: "15px",
                  width: "15px",
                  backgroundColor: "black",
                }}
              />
              <label className="text-gray-300">Keep me logged in</label>
            </div>

            <div className="border-b border-b-gray-500"></div>

            <Button
              type="submit"
              className="w-full py-2 px-6 font-bold text-gray-100 bg-indigo-600 rounded-xl hover:bg-indigo-700 transition-all duration-300 shadow-md hover:shadow-lg focus:ring-2 focus:ring-indigo-500"
            >
              {isLoading ? "Logging In ..." : "Log In"}
            </Button>

            <button
              onClick={() => navigate("/signup")}
              className="w-full py-2 font-bold border-2 rounded-xl hover:bg-blue-100 transition duration-300"
              style={{ color: "#7E82ED", borderColor: "#7E82ED" }}
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
