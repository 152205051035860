import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userBomaData: null, //all the boma data related to a user

  filteredData: [], //boma data after implementing date and location filter

  isAnalysing: false, //state to store if we are analysing or not

  isSidebarOpen: false,

  //circle coordinates
  circleCoordinates: [
    [77.209, 28.6139],
    [77.21, 28.615],
    [77.205, 28.61],
    [77.212, 28.612],
  ],

  //BOMA CLUSTER
  showBomaCluster: false,
  bomaClusterDetails: null,

  //REVIEW BOMA DETAILs
  isReviewing: false,
  bomaReviewDetails: null,
  imageReviewDetails: null,

  bomaAnalysisDetails: null,

  //review images name to id dictionary
  imagesNameToId: null,

  highlightedBomaDetails: null,
  highlightedImageDetails: null,

  bomaCattleCount: null,

  bomaPopupDetails: null,

  activePopup: null,

  showDeleteBomaModal: false, //model to confirm the boma deletion
  bomaIdToBeDeleted: null,

  //state to reload the app
  bomaCreated: 0,
  bomaDeleted: 0,

  jobStatusList: {},

  //analysis job list
  analysisJobList: [],

  additionalBomaDetails: {}, //store all the additonal boma details like cattle count, job status, group id - all in one place

  bomaGroupIdDetails: {},
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setUserBomaData: (state, action) => {
      state.userBomaData = action.payload;
    },

    setFilteredData: (state, action) => {
      state.filteredData = action.payload;
    },

    setIsAnalysing: (state, action) => {
      state.isAnalysing = action.payload;
    },

    setIsSidebarOpen: (state, action) => {
      state.isSidebarOpen = action.payload;
    },

    setBomaGroupIdDetails: (state, action) => {
      state.bomaGroupIdDetails = {
        ...state.bomaGroupIdDetails,
        ...action.payload,
      };
    },

    removeFromGroupId: (state, action) => {
      // Create a new object without the key that matches action.payload
      const { [action.payload]: _, ...newCollectionGroupMap } =
        state.bomaGroupIdDetails;

      // Update the state with the new collectionGroupMap
      state.bomaGroupIdDetails = newCollectionGroupMap;
    },

    //BOMA CLUSTER
    setShowBomaCluster: (state, action) => {
      state.showBomaCluster = action.payload;
    },
    setBomaClusterDetails: (state, action) => {
      state.bomaClusterDetails = action.payload;
    },

    //REVIEW
    setIsReviewing: (state, action) => {
      state.isReviewing = action.payload;
    },
    setBomaReviewDetails: (state, action) => {
      state.bomaReviewDetails = action.payload;
    },
    setImageReviewDetails: (state, action) => {
      state.imageReviewDetails = action.payload;
    },

    setBomaAnalysisDetails: (state, action) => {
      state.bomaAnalysisDetails = action.payload;
    },

    setImagesNameToId: (state, action) => {
      state.imagesNameToId = action.payload;
    },

    setHighlightedBomaDetails: (state, action) => {
      state.highlightedBomaDetails = action.payload;
    },
    setHighlightedImageDetails: (state, action) => {
      state.highlightedImageDetails = action.payload;
    },

    setBomaCattleCount: (state, action) => {
      state.bomaCattleCount = action.payload;
    },

    setBomaPopupDetails: (state, action) => {
      state.bomaPopupDetails = action.payload;
      // //console.log("NEW BOMA POPUP DETAILS", state.bomaPopupDetails)
    },
    setActivePopup: (state, action) => {
      state.activePopup = action.payload;
    },

    setShowDeleteBomaModal: (state, action) => {
      state.showDeleteBomaModal = action.payload;
    },
    setBomaIdToBeDeleted: (state, action) => {
      state.bomaIdToBeDeleted = action.payload;
    },

    setBomaDeleted: (state, action) => {
      state.bomaDeleted = state.bomaDeleted + 1;
    },

    setJobStatusList: (state, action) => {
      state.jobStatusList = action.payload;
    },

    setAnalysisJobList: (state, action) => {
      // if (typeof action.payload == "number")
      //   if(state.analysisJobList.indexOf(action.payload) === -1)
      //   state.analysisJobList.push(action.payload);
      // else {
      //   state.analysisJobList = action.payload;
      // }

      if (Array.isArray(action.payload)) {
        state.analysisJobList = action.payload;
      } else if (typeof action.payload === "number") {
        if (state.analysisJobList.indexOf(action.payload) === -1) {
          state.analysisJobList.push(action.payload);
        }
      }
      



    },
  },
});

export const {
  setUserBomaData,
  removeFromGroupId,
  setFilteredData,
  setIsAnalysing,
  setIsSidebarOpen,

  //CLUSTER
  setShowBomaCluster,
  setBomaClusterDetails,

  //REVIEW
  setIsReviewing,
  setBomaReviewDetails,
  setImageReviewDetails,
  setBomaAnalysisDetails,

  setImagesNameToId,
  setHighlightedBomaDetails,
  setHighlightedImageDetails,

  setBomaCattleCount,
  setBomaPopupDetails,
  setActivePopup,

  setShowDeleteBomaModal,
  setBomaIdToBeDeleted,

  setBomaDeleted,

  setJobStatusList,
  setAnalysisJobList,

  setBomaGroupIdDetails,
} = globalSlice.actions;
export default globalSlice.reducer;
