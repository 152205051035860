import React from "react";

const UngroupCollectionModel = ({
  isOpen,
  collection_id,
  handleCancelButton,
  handleUngroupClick,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
      <div
        className="bg-gray-800 p-8 rounded-md text-center shadow-lg border border-gray-600"
        style={{ background: "#1E1E20" }}
      >
        <h2 className="text-2xl font-bold text-white mb-4">
          Do you wish to ungroup the collection {collection_id}
        </h2>

        <div className="flex">
          <button
            onClick={handleCancelButton}
            className="block w-full p-2 mr-3 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 transition duration-300 "
          >
            Cancel
          </button>
          <button
            onClick={handleUngroupClick}
            className="block w-full p-2 bg-red-600 text-white rounded-md shadow-md hover:bg-red-700 transition duration-300 "
          >
            Ungroup
          </button>
        </div>
      </div>
    </div>
  );
};

export default UngroupCollectionModel;
