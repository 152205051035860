import mapboxgl from "mapbox-gl";

export const extractDateAndDay = (timestamp) => {
  // Convert the timestamp into a Date object
  const dateObject = new Date(timestamp);
  
  // Extract parts of the date
  const day = dateObject.toLocaleDateString('en-US', { weekday: 'short' }); // "Wed"
  const date = dateObject.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' }); // "01 Jan 2025"
  
  // Combine the parts
  return `${day}, ${date}`;
};


export const createPopup = ({
  mapRef,
  coordinates,
  featureDetails,
  isLoggedIn,
  onReviewClick,
}) => {
  const { latitude, longitude } = coordinates;
  const { id, region, datetime } = featureDetails;

  if (!latitude || !longitude) return;

  const popupAvailable = document.getElementsByClassName("mapboxgl-popup");
  if (popupAvailable.length) {
    popupAvailable[0].remove();
  }

  const container = document.createElement("div");
  container.className = "popup-container";
  container.style.position = "relative";

  const bomaId = document.createElement("p");
  bomaId.innerHTML = `<strong>Collection Id : </strong> ${id}`;

  const bomaLocation = document.createElement("p");
  bomaLocation.innerHTML = `<strong>Region : </strong> ${region}`;

  const bomaDateTime = document.createElement("p");
  bomaDateTime.innerHTML = `<strong>Date-Time : </strong> ${extractDateAndDay(datetime)}`;

  container.appendChild(bomaId);
  container.appendChild(bomaLocation);
  container.appendChild(bomaDateTime);

  if (isLoggedIn) {
    const reviewButton = document.createElement("button");
    reviewButton.textContent = "Review";
    reviewButton.className = "review-button";
    reviewButton.style.cssText =
      "padding: 5px 10px; cursor: pointer; margin-right: 5px; background-color: #4A90E2; color: white; border: none; border-radius: 4px;";

    reviewButton.addEventListener("click", (e) => {
      e.stopPropagation();
      if (onReviewClick) onReviewClick(featureDetails);
    });

    container.appendChild(reviewButton);
  }

  // Create a new popup
  const popup = new mapboxgl.Popup({ offset: 25, closeButton: false })
    .setLngLat([longitude, latitude])
    .setDOMContent(container)
    .addTo(mapRef);
};
