import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isUploading: false, //state to store if we are uploading images
    collectionId: null, //id of collection to which files will be uploaded
    showUploadModal: false, //state to store when to show the upload modal
    // totalUploads: 0,      //state to store number of total files we are uploading
    selectedFiles: [],  //list of files selected to be uploaded
    uploadStatus: [],   //status of each files which is to be uploaded
    failedUploads: [],  //list of failed upload files
    successfulUploads: 0,   //count of successfule uploads
    totalFiles: 0,  //list of total files to be uploaded
    currentFileUpload: "",  //which file is being uploaded
    uploadSuccessful:false,  //all the files are uploaded or not
    bomaCreated: 0, // when upload is successful
};

const fileUploadSlice = createSlice({
    name: "filesUpload",
    initialState,
    reducers: {
        setIsUploading: (state, action) => {
            state.isUploading = action.payload;
        },
        setCollectionId: (state,action) => {
            state.collectionId = action.payload;
        },
        setShowUploadModal: (state, action) => {
            state.showUploadModal = action.payload;
        },
        // setTotalUploads: (state, action) => {
        //     state.totalUploads = action.payload;
        // },
        setSelectedFiles: (state, action) => {
            state.selectedFiles = action.payload;
        },
        setUploadStatus: (state, action) => {
            const incomingFileName = action.payload.name; 
            const existingIndex = state.uploadStatus.findIndex(item => item.name === incomingFileName);
            if (existingIndex !== -1) {
              state.uploadStatus[existingIndex] = action.payload;
            } else {
              state.uploadStatus.push(action.payload);
            }
        },
        setFailedUploads: (state, action) => {
            state.failedUploads = action.payload;
        },
        addFailedUploads: (state,action)=>{
            state.failedUploads = [...state.failedUploads,action.payload]
        },
        clearFailedUploads:(state,action)=>{
            state.failedUploads = [];
        },
        setSuccessfulUploads: (state, action) => {
            state.successfulUploads = action.payload;
        },
        setTotalFiles: (state, action) => {
            state.totalFiles = action.payload;
        },
        clearUploadStatus: (state,action) => {
            state.setUploadStatus = [];
        },
        setCurrentFileUpload: (state,action) => {
            state.currentFileUpload = action.payload;
        },
        setBomaCreated: (state,action) => {
            state.bomaCreated = state.bomaCreated +1;
            //reset other states of the files in store
            state.currentFileUpload = "";
            state.isUploading= false;
            state.totalUploads= 0;
            state.showUploadModal = false;
            //files
            state.selectedFiles= [];
            state.uploadStatus= [];
            state.failedUploads= [];
            state.successfulUploads= 0;
            state.totalFiles= 0;
        },
        setUploadSuccessful : (state, action) => {
            state.uploadSuccessful = action.payload;
        },
    }
});

export const {
    setIsUploading,
    setCollectionId,
    setShowUploadModal,
    // setTotalUploads,
    setSelectedFiles,
    setUploadStatus,
    setFailedUploads,
    addFailedUploads,
    clearFailedUploads,
    setSuccessfulUploads,
    setTotalFiles,
    clearUploadStatus,
    setCurrentFileUpload,
    setBomaCreated,
    setUploadSuccessful,

} = fileUploadSlice.actions;

export default fileUploadSlice.reducer;

//total uploads and total files
// failed upload