import React from "react";
import { XIcon } from "@heroicons/react/solid";

const BomaGroupModel = ({
  isOpen,
  bomaList,
  handleRemove,
  handleCreateGroup,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 transition-opacity duration-300 ease-in-out">
      <div className="bg-[#39393E] rounded-md shadow-lg w-11/12 sm:w-3/4 max-w-3xl relative transform transition-transform duration-300 ease-in-out scale-100 p-6">
        {/* Header */}
        <div className="flex justify-between mb-4">
          <h2 className="text-white text-lg font-semibold">Group Bomas</h2>
          <button
            onClick={onClose}
            className="text-gray-800 hover:text-gray-900 transition-colors duration-200 ease-in-out"
          >
            <XIcon className="h-6 bg-white" />
          </button>
        </div>

        {/* Table */}
        <div className="overflow-x-auto max-h-80">
          <table className="w-full text-left text-white">
            <thead>
              <tr className="bg-[#2A2A2E]">
                <th className="px-4 py-2">Boma ID</th>
                <th className="px-4 py-2">Location</th>
                <th className="px-4 py-2">Date Created</th>
                <th className="px-4 py-2 text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {bomaList.map((boma, index) => (
                <tr
                  key={index}
                  className={`border-b border-[#3A3A6A] ${
                    index % 2 === 0 ? "bg-[#2D2D33]" : "bg-[#2A2A2E]"
                  }`}
                >
                  <td className="px-4 py-2">{boma.id}</td>
                  <td className="px-4 py-2">{boma.region}</td>
                  <td className="px-4 py-2">{boma.timeCreated}</td>
                  <td className="px-4 py-2 text-right">
                    <button
                      onClick={() => handleRemove(boma)}
                      className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600"
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Footer */}
        <div className="mt-6 text-right">
          <button
            onClick={handleCreateGroup}
            className={`bg-blue-500 text-white px-5 py-2 rounded-md hover:bg-blue-600 disabled:bg-gray-500 disabled:cursor-not-allowed`}
            disabled={bomaList.length < 2}
          >
            Create Group
          </button>
        </div>
      </div>
    </div>
  );
};

export default BomaGroupModel;
