import React, { useEffect, useState } from "react";
import reportDownload from "../../assets/icons/reportDownload.png"

const ReportComponent = () => {
  const reports = Array(8).fill({
    name: "Kilkan, direction...",
    date: "12/23/24",
    time: "16:23:00",
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>{
    async function fetchReports(){
        setIsLoading(true);
        ////console.log("FETCHING ALL REPORTS")
        setTimeout(()=> {
            setIsLoading(false);
        }, 3000)
    }
    fetchReports();
  },[])

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center mt-4 h-40">
          <div className="w-10 h-10 border-4 border-t-4 border-t-blue-500 border-gray-200 rounded-full animate-spin"></div>
        </div>
      ) : (
        <div className="text-white space-y-2 overflow-y-auto">
          <h2 className="text-md mt-3 mb-2 ml-4">Last reports</h2>
          {reports.map((report, index) => (
            <div
              key={index}
              className="flex justify-between items-center px-3 py-2 hover:border hover:border-blue-500 cursor-pointer"
            >
              <div>
                <p className="truncate">{report.name}</p>
                <p className="text-sm">{`${report.date} at ${report.time}`}</p>
              </div>
              <button className="text-blue-500 hover:scale-105 transition-transform">
                <img src={reportDownload} alt="report icon" className="w-5 mr-4" />
              </button>
            </div>
          ))}
        </div>
      )}
    </>
  );
  
};

export default ReportComponent;
