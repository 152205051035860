import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsReviewing,
  setImageReviewDetails,
  setBomaReviewDetails,
  setImagesNameToId,
} from "../../store/slices/globalSlice.js";

import backArrow from "../../assets/icons/backArrow.png";
import downloadIcon from "../../assets/icons/downloadIcon.png";
import ImageModal from "../modals/ImageModal.jsx";
import MosaicImageViewModal from "../modals/MosaicImageViewModal.jsx";

import axios from "axios";

import zoomIcon from "../../assets/icons/zoomIcon.png";
import { toast } from "react-toastify";

import { extractDateAndDay } from "../../utils/createPopup.js";
import TimeLineGraph from "../TimeLineGraph.jsx";
import TimeLineGraphModel from "../modals/TimeLineGraphModel.jsx";
import MapModal from "../modals/MapModel.jsx";

import mapIcon from "../../assets/icons/map.png"

const ReviewDetails = () => {
  const dispatch = useDispatch();

  let bomaAnalysisDetails = useSelector(
    (state) => state.global.bomaReviewDetails
  );
  bomaAnalysisDetails = JSON.parse(bomaAnalysisDetails);

  const bomaCattleCount = useSelector((state) => state.global.bomaCattleCount);
  const imageReviewData = useSelector(
    (state) => state.global.imageReviewDetails
  );

  const highlightedImageDetails = useSelector(
    (state) => state.global.highlightedImageDetails
  );

  //console.log("IMAGE REVIEW DATA IN REVIEW LAYER", imageReviewData)

  const imagesNameToIdDictonary = useSelector(
    (state) => state.global.imagesNameToId
  );

  const [isLoadingThumbnail, setIsLoadingThumbnail] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  //large boma image
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [largeImageUrl, setLargeImageUrl] = useState(null);
  const [isLoadingLargeImage, setIsLoadingLargeImage] = useState(false);

  //mosaic image
  const [isMosaicImageModelOpen, setIsMosaicImageModelOpen] = useState(false);
  const [mosaicImageModelUrl, setMosaicImageModelUrl] = useState(null);
  const [isLoadingMosaicImage, setIsLoadingMosaicImage] = useState(false);

  const [isMosaicThumbnailLoading, setIsMosaicThumbnailLoading] =
    useState(false);
  const [mosaicThumbnailUrl, setMosaicThumbnailUrl] = useState(null);

  const bomaGroupIdDetails = useSelector(
    (state) => state.global.bomaGroupIdDetails
  );
  const [isTimelineModelVisible, setIsTimelineModelVisible] = useState(false);

  const [isTimelineLoading, setIsTimelineLoading] = useState(false);
  const [timelineData, setTimelineData] = useState([]);

  //map Model
  const [showMapModel, setShowMapModel] = useState(false);
  const [mapUrl, setMapUrl] = useState(null);



  useEffect(() => {
    const fetchData = async () => {
      const timeline_url = `${
        process.env.REACT_APP_AUTH_BASE_URL
      }analytics/results/collections/timeline?group_id=${
        bomaGroupIdDetails?.[bomaAnalysisDetails.properties.id]
      }`;
      const accessToken = sessionStorage.getItem("accessToken");
      setIsTimelineLoading(true);
      try {
        const result = await axios.get(timeline_url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        //console.log("timeline data ", result);
        setTimelineData(result.data);
        setIsTimelineLoading(false);
      } catch (error) {
        //console.log("error in graph ", error);
        setIsTimelineLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    async function getDetailedBomaImagesList() {
      const URL = `${process.env.REACT_APP_AUTH_BASE_URL}images/metadata`;
      ////console.log("line 155", URL);
      try {
        const queryParam = new URLSearchParams({
          collection_id: bomaAnalysisDetails?.properties?.id,
        });

        const response = await fetch(`${URL}?${queryParam}`, {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const imagesIdName = {};
        data.forEach((item) => {
          imagesIdName[item.name] = item.id;
        });

        dispatch(setImagesNameToId(imagesIdName));
      } catch (error) {
        //console.error("There was an error with the request:", error);
      }
    }

    getDetailedBomaImagesList();
  }, [bomaAnalysisDetails?.properties?.id, dispatch]);

  useEffect(() => {
    async function getImageThumbnail() {
      if (!imageReviewData) return;

      if (!imagesNameToIdDictonary) return;

      //console.log(imagesNameToIdDictonary)
      //console.log(imageReviewData.properties)
      const imageId =
        imagesNameToIdDictonary[imageReviewData.properties.imageName];
      const URL = `${process.env.REACT_APP_AUTH_BASE_URL}analytics/results/images/${imageId}/blob`;

      const accessToken = sessionStorage.getItem("accessToken");

      try {
        setIsLoadingThumbnail(true); // Start loading state

        const queryParam = new URLSearchParams({
          id: imagesNameToIdDictonary[imageReviewData.properties.imageName],
          thumbnail: true,
        });

        const response = await fetch(`${URL}?${queryParam}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "image/png",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const imageBlob = await response.blob();
        const imageUrl = window.URL.createObjectURL(imageBlob);
        //console.log("DEFAULT IMAGE URL", imageUrl)
        setThumbnailUrl(imageUrl);
      } catch (error) {
        //console.log("DEFAULT IMAGE URL - error")
        //console.log("There was an error with the request:", error);
      } finally {
        setIsLoadingThumbnail(false); // End loading state
      }
    }

    getImageThumbnail();
  }, [imageReviewData, imagesNameToIdDictonary]);

  useEffect(() => {
    async function getMosaicThumbnail() {
      if (!bomaAnalysisDetails?.properties?.id) return;
      const collection_id = bomaAnalysisDetails?.properties?.id;
      // //console.log("fetching mosaic image thumbnail ", collection_id);

      const mosaic_thumbnail_url = `${process.env.REACT_APP_AUTH_BASE_URL}analytics/results/collections/${collection_id}/blob?thumbnail=true`;
      const accessToken = sessionStorage.getItem("accessToken");

      try {
        setIsMosaicThumbnailLoading(true); // Start loading state

        const response = await fetch(`${mosaic_thumbnail_url}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "image/png",
          },
        });
        // //console.log(response);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const imageBlob2 = await response.blob();
        const imageUrl2 = window.URL.createObjectURL(imageBlob2);
        setMosaicThumbnailUrl(imageUrl2);
      } catch (error) {
        //console.log("There was an error with the request:", error);
      } finally {
        setIsMosaicThumbnailLoading(false); // End loading state
      }
    }

    getMosaicThumbnail();
  }, [bomaAnalysisDetails?.properties?.id]);

  const handleBackButtonClick = () => {
    dispatch(setIsReviewing(false));
    dispatch(setBomaReviewDetails(null));
    dispatch(setImageReviewDetails(null));
  };

  const handleImageClick = async () => {
    setIsModalOpen(true);
    setIsLoadingLargeImage(true);

    const URL = `${process.env.REACT_APP_AUTH_BASE_URL}images/blob`;
    const accessToken = sessionStorage.getItem("accessToken");

    try {
      const queryParam = new URLSearchParams({
        id: imagesNameToIdDictonary[imageReviewData.properties.imageName],
      });

      const response = await fetch(`${URL}?${queryParam}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "image/png",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const imageBlob = await response.blob();
      const imageUrl = window.URL.createObjectURL(imageBlob);
      setLargeImageUrl(imageUrl);
    } catch (error) {
      //console.error("There was an error with the request:", error);
    } finally {
      setIsLoadingLargeImage(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setLargeImageUrl(null);
  };

  const handleMosaicImageViewClick = async () => {
    setIsMosaicImageModelOpen(true);
    setIsLoadingMosaicImage(true);

    const id = bomaAnalysisDetails?.properties?.id;
    //console.log(id);

    const URL = `${process.env.REACT_APP_AUTH_BASE_URL}analytics/results/collections/${id}/blob`; //update with mosaic image url
    const accessToken = sessionStorage.getItem("accessToken");

    try {
      const response = await fetch(`${URL}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "image/png",
        },
      });

      //console.log(response);

      if (!response.ok) {
        toast.info("Internal server error");
        setIsMosaicImageModelOpen(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const imageBlob = await response.blob();
      const imageUrl = window.URL.createObjectURL(imageBlob);
      setMosaicImageModelUrl(imageUrl);
    } catch (error) {
      console.error("There was an error with the request:", error);
    } finally {
      setIsLoadingMosaicImage(false);
    }
  };

  const handleCloseMosaicImageModel = () => {
    setIsMosaicImageModelOpen(false);
  };

  // //console.log("mosaic url ", mosaicThumbnailUrl);
  // //console.log("thumbnail url", thumbnailUrl);

  const handleMapIconClick = () => {
    const latitude = bomaAnalysisDetails.geometry.coordinates[0];
    const longitude = bomaAnalysisDetails.geometry.coordinates[1];
    const API_KEY = process.env.REACT_APP_MAPS_API_KEY;
    const map_url = `https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${longitude},${latitude}&zoom=20&maptype=satellite`;
    setMapUrl(map_url);
    // console.log([latitude, longitude])
    // console.log(map_url)
    setShowMapModel(true);
  }



  return (
    <div
      className="text-white w-72 h-full overflow-y-auto"
      style={{ background: "#1E1E20" }}
    >
      <img
        src={backArrow}
        alt="back button"
        className="mt-6 ml-3 h-4 cursor-pointer"
        onClick={handleBackButtonClick}
      />

      <div className="mt-2 p-3">
        <div className="flex justify-between items-center">
          <p className="text-2xl font-semibold pb-2">Reviewing</p>
          <img
            src={downloadIcon}
            alt="download icon"
            className="cursor-pointer h-6"
          />
        </div>

        <p>
          <strong>Total Cattle:</strong>{" "}
          {bomaCattleCount &&
          bomaCattleCount.hasOwnProperty(bomaAnalysisDetails.properties.id)
            ? bomaCattleCount[bomaAnalysisDetails.properties.id]
            : `N/A`}
        </p>
          
          <div className="flex space-x-3.5">

        <p>
          <strong>Region:</strong> {bomaAnalysisDetails.properties.region}
        </p>
        <img 
          src={mapIcon}
          alt="map_icon"
          className="w-6 h-6 cursor-pointer"
          onClick={handleMapIconClick }
        />
          </div>

        <p>
          <strong>Collection ID:</strong> {bomaAnalysisDetails.properties.id}
        </p>

        <p>
          <strong>Group ID:</strong>{" "}
          {bomaGroupIdDetails?.[bomaAnalysisDetails.properties.id] || `N/A`}
        </p>

        <div className="py-1" />

        <p>
          <strong>Date:</strong>{" "}
          {extractDateAndDay(bomaAnalysisDetails.properties.timeCreated)}
        </p>

        {/* <p>
          <strong>Make:</strong> {bomaAnalysisDetails.properties.make}
        </p>
        <p>
          <strong>Model:</strong> {bomaAnalysisDetails.properties.model}
        </p> */}

        {/* <p>
          <strong>Latitude:</strong>{" "}
          {bomaAnalysisDetails.geometry.coordinates[0]}
        </p>
        <p>
          <strong>Longitude:</strong>{" "}
          {bomaAnalysisDetails.geometry.coordinates[1]}
        </p> */}

        {/* <button
          onClick={() => {
            const latitude = bomaAnalysisDetails.geometry.coordinates[0];
            const longitude = bomaAnalysisDetails.geometry.coordinates[1];
            const API_KEY = process.env.REACT_APP_MAPS_API_KEY;
            console.log(API_KEY)
            // const map_url = `https://www.google.com/maps/embed/v1/view?key=${API_KEY}&center=${latitude},${longitude}&zoom=12&maptype=satellite`;
            const map_url = `https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${latitude},${longitude}&zoom=18&maptype=satellite`;

            setMapUrl(map_url);
            console.log(map_url)
            setShowMapModel(true);
          }}
        >
          Maps URL
        </button> */}

      </div>

      {isTimelineLoading ? (
        <div className="flex justify-center items-center mt-4 h-40">
          {" "}
          {/* Container to center */}
          <div className="w-10 h-10 border-4 border-t-4 border-t-blue-500 border-gray-200 rounded-full animate-spin"></div>
        </div>
      ) : timelineData.length === 0 ? null : (
        <>
          <div className="relative inline-blockpt-2  mx-auto mt-4  ">
            <TimeLineGraph
              data={timelineData}
              handleZoom={setIsTimelineModelVisible}
              highlightCollectionId={bomaAnalysisDetails?.properties?.id}
            />

            {/* <TestChart /> */}
          </div>
        </>
      )}

      <div className="border-b border-b-gray-500 py-2 w-full"></div>

      <div className=" justify-between p-3">
        <p className="text-xl "> MOSAIC IMAGE </p>

        {isMosaicThumbnailLoading ? (
          <div className="flex justify-center items-center mt-4 h-40">
            {" "}
            {/* Container to center */}
            <div className="w-10 h-10 border-4 border-t-4 border-t-blue-500 border-gray-200 rounded-full animate-spin"></div>
          </div>
        ) : (
          <>
            <div className="relative inline-blockpt-2  mx-auto mt-4 ">
              {" "}
              {/* relative positioning wrapper */}
              {mosaicThumbnailUrl ? (
                <>
                  <img
                    src={mosaicThumbnailUrl}
                    alt="mosaic thumbnail"
                    className=""
                  />

                  <img
                    src={zoomIcon}
                    alt="zoom"
                    className="absolute top-0 right-0 w-8 cursor-pointer" // absolute positioning for top-right corner
                    onClick={handleMosaicImageViewClick}
                  />
                </>
              ) : (
                "No Mosaic image exist"
              )}
            </div>
          </>
        )}

        {/* 
          <img
            src={zoomIcon}
            alt="zoom"
            className="h-8 cursor-pointer" // absolute positioning for top-right corner
            onClick={handleMosaicImageViewClick}
          /> */}
      </div>

      <div className="border-b border-b-gray-500  w-full"></div>

      {imageReviewData && (
        <div className="mt-2 p-3">
          <p>
            <strong>Image Title:</strong> {imageReviewData.properties.imageName}
          </p>
          <p>
            <strong>Latitude:</strong> {imageReviewData.geometry.coordinates[0]}
          </p>
          <p>
            <strong>Longitude:</strong>{" "}
            {imageReviewData.geometry.coordinates[1]}
          </p>

          {isLoadingThumbnail ? (
            <div className="flex justify-center items-center mt-4 h-40">
              {" "}
              {/* Container to center */}
              <div className="w-10 h-10 border-4 border-t-4 border-t-blue-500 border-gray-200 rounded-full animate-spin"></div>
            </div>
          ) : (
            <>
              <div className="relative inline-blockpt-2  mx-auto mt-4 ">
                {" "}
                {/* relative positioning wrapper */}
                <img src={thumbnailUrl} alt="thumbnail" className="" />
                <img
                  src={zoomIcon}
                  alt="zoom"
                  className="absolute top-0 right-0 w-8 cursor-pointer" // absolute positioning for top-right corner
                  onClick={handleImageClick}
                />
              </div>
            </>
          )}
        </div>
      )}

      <ImageModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        imageUrl={largeImageUrl}
        isLoading={isLoadingLargeImage}
        imgName={imageReviewData?.properties?.imageName}
        bomaId={bomaAnalysisDetails?.properties?.id}
      />

      {/* all local states */}
      <MosaicImageViewModal
        isOpen={isMosaicImageModelOpen}
        onClose={handleCloseMosaicImageModel}
        imageUrl={mosaicImageModelUrl}
        isLoading={isLoadingMosaicImage}
        bomaId={bomaAnalysisDetails?.properties?.id}
      />

      {/* timeline graph model */}

      <TimeLineGraphModel
        data={timelineData}
        isOpen={isTimelineModelVisible}
        onClose={() => setIsTimelineModelVisible(false)}
        highlightCollectionId={bomaAnalysisDetails?.properties?.id}
      />

      <MapModal
        isOpen={showMapModel}
        onClose={() => {
          setShowMapModel(false);
          setMapUrl(null);
        }}
        mapUrl={mapUrl}
      />
    </div>
  );
};

export default ReviewDetails;
