import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bomaPopupDetailsMap : null,
    clickedCollectionId : null,
    checkedCollectionList : [],
}

const mapLayerSlice = createSlice({
    name : "mapLayer",
    initialState,
    reducers : {
        //mapping to store all boma detais for popup for a date cluster in key value pair
        setBomaPopupDetailsMap : (state,action) => {
            state.bomaPopupDetailsMap = action.payload;
        },
        //used to select the collection in the sideMenu
        setClickedCollectionId : (state,action) => {
            state.clickedCollectionId = action.payload;
        },
        //used to keep a record of checked collection in sidemenu
        setCheckedCollectionList: (state,action) => {
            state.checkedCollectionList = action.payload;
        }
    }
})

export const {setBomaPopupDetailsMap, setClickedCollectionId , setCheckedCollectionList} = mapLayerSlice.actions;
export default mapLayerSlice.reducer;