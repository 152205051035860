import React, { useEffect} from "react";
import MapComponent from "../components/map/MapComponent.jsx";
import Navbar from "../components/Navbar.jsx";
import SideMenu from "../components/sideMenu/SideMenu.jsx";
import Footer from "../components/Footer.jsx";
import UploadImage from "../components/modals/UploadImage.jsx";
import { useDispatch, useSelector } from "react-redux";
import autoLogin from "../utils/autoLogin.js";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showUploadModal = useSelector((state) => state.upload.showUploadModal);


  useEffect(() => {
    autoLogin(dispatch, navigate);
  }, [dispatch, navigate]);


  return (
    <div className="relative h-screen w-full">
      <Navbar />
      <SideMenu   />
      <MapComponent/>
      <Footer />
      {showUploadModal && <UploadImage />}
    </div>
  );
};

export default Home;
