import React, { useEffect, useState } from "react";
import { ChevronRightIcon, DotsVerticalIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowBomaCluster,
  setBomaClusterDetails,
  setHighlightedBomaDetails,
  setBomaPopupDetails,
  setShowDeleteBomaModal,
  setBomaIdToBeDeleted,
  setAnalysisJobList,
  setJobStatusList,
  setBomaGroupIdDetails,
  removeFromGroupId,
  setFilteredData,
} from "../../store/slices/globalSlice";

import {
  setShowUploadModal,
  setCollectionId,
} from "../../store/slices/filesUploadSlice";


import {setBomaPopupDetailsMap, setClickedCollectionId, setCheckedCollectionList} from "../../store/slices/mapLayersSlice"



import { groupGeoJSONByDate } from "../../utils/helperFun";
import axios from "axios";
import usePolling from "../../hooks/usePolling";
import { toast } from "react-toastify";
import BomaGroupModel from "../modals/BomaGroupModel";
import UngroupCollectionModel from "../modals/UngroupCollectionModel";

const EarthquakeList = () => {
  // //console.log("EARTHQUACKE LIST RENDERED");
  const [expandedDate, setExpandedDate] = useState([]); // Track which date group is expanded
  const [openMenuId, setOpenMenuId] = useState(null); // Track which menu is open
  // Track clicked item ID
  const clickedCollectionId = useSelector((state) => state.mapLayer.clickedCollectionId);
  const checkedCollectionList = useSelector((state) => state.mapLayer.checkedCollectionList);

  //boma group model
  const [isBomaGroupModelOpen, setIsBomaGroupModelOpen] = useState(false);

  const bomaCattleCount = useSelector((state) => state.global.bomaCattleCount);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const dispatch = useDispatch();

  const filteredData = useSelector((state) => state.global.filteredData);

  // Group data by date
  const groupedDataByDate = groupGeoJSONByDate(filteredData);

  const availableBomaDataLength = Object.keys(groupedDataByDate).length;

  const jobStatusList = useSelector((state) => state.global.jobStatusList); //current job status
  const analysisJobList = useSelector((state) => state.global.analysisJobList); //list of jobs

  const isUploading = useSelector((state) => state.global.isUploading);

  const bomaGroupIdDetails = useSelector(
    (state) => state.global.bomaGroupIdDetails
  );

  //analysis api calls
  usePolling(analysisJobList, jobStatusList, dispatch);

  const [isUngroupModelOpen, setIsUngroupModelOpen] = useState(false);
  const [collectionIdToUngroup, setCollectionIdToUngroup] = useState(null);



  // if (analysisJobList.length > 0) {
  //   ////console.log(analysisJobList);
  //   const intervalId = setInterval(async () => {
  //     ////console.log("POLLING CALL FOR UPDATED RESULTS");

  //     try {
  //       // Make the API call
  //       const response = await fetch(`${BASE_URL}analytics/collections`, {
  //         method: "GET",
  //         headers: { Authorization: `Bearer ${accessToken}` },
  //       });

  //       if (!response.ok) {
  //         throw new Error(`Failed to fetch: ${response.statusText}`);
  //       }

  //       const data = await response.json();

  //       // Process the data
  //       let pendingListOfJobs = [];
  //       data.forEach((item) => {
  //         const bomaId = item.id;
  //         pendingListOfJobs.push(bomaId);
  //       });

  //       const completedBomaJobs = analysisJobList.filter(
  //         (item) => !pendingListOfJobs.includes(item)
  //       );
  //       const updatedJobStatus = { ...jobStatusList };
  //       completedBomaJobs.forEach((item) => {
  //         updatedJobStatus[item] = "completed";
  //       });

  //       if (pendingListOfJobs.length !== analysisJobList.length) {
  //         dispatch(setAnalysisJobList(pendingListOfJobs));
  //         // Update the job status list
  //         dispatch(setJobStatusList(updatedJobStatus));
  //       }
  //       //console.log("MADE API CALL FOR JOB STATUS API");
  //     } catch (error) {
  //       //console.error("Failed to fetch job status:", error);
  //     }

  //     // Stop polling if there are no jobs left
  //     if (analysisJobList.length === 0) {
  //       clearInterval(intervalId);
  //     }
  //   }, 15000);
  // }

  function handleSideMenuClick(feature, entry) {
    const bomaClusterInputData = {
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: {
          name: "urn:ogc:def:crs:OGC:1.3:CRS84",
        },
      },
      features: feature,
    };

    const popupDetails = {
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: {
          name: "urn:ogc:def:crs:OGC:1.3:CRS85",
        },
      },
      features: [entry],
    };


    const collectionMappingForPopup = feature.reduce((acc, item) => {
      const id = item.properties.id;
      acc[id] = item;
      return acc;
    }, {});
    dispatch(setBomaPopupDetailsMap(collectionMappingForPopup));
    dispatch(setBomaPopupDetails(popupDetails));
    dispatch(setBomaClusterDetails(bomaClusterInputData));
    dispatch(setShowBomaCluster(true));
    dispatch(setHighlightedBomaDetails({
      latitude : entry.geometry.coordinates[0],
      longitude : entry.geometry.coordinates[1]
    }));
    dispatch(setClickedCollectionId(entry.properties.id)); // Update state with clicked item ID
  }

  function handleDeleteClick(bomaId) {
    ////console.log("DELETE BOMA CLICKED");

    //open modal
    dispatch(setShowDeleteBomaModal(true));
    dispatch(setBomaIdToBeDeleted(bomaId));

    setOpenMenuId(null); // Close the menu
  }

  async function handleAnalyzeClick(bomaId) {
    setOpenMenuId(null); // Close the menu

    //check before if any analysis is already running
    const analysisAlreadyRunning = analysisJobList.indexOf(bomaId);
    if (analysisAlreadyRunning !== -1) return; //analysis already running

    ////console.log("ANALYZE BOMA CLICKED");

    const BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;
    const token = sessionStorage.getItem("accessToken");

    //make a request to do analysis

    try {
      const response = await axios.post(
        `${BASE_URL}analytics/collections/${bomaId}`,
        {
          module: process.env.REACT_APP_ANALYSIS_MODULE,
          apiurl: process.env.REACT_APP_ANALYSIS_API_URL,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      ////console.log("ANALYTICS RESPONSE", response);
      dispatch(setAnalysisJobList(bomaId));
    } catch (error) {
      //console.error("Error in analysis", error);
    }

    //toast to show analysis started
  }

  function handleAddImages(bomaId) {
    dispatch(setCollectionId(bomaId));
    dispatch(setShowUploadModal(true));
    setOpenMenuId(null); // Close the menu
  }

  const handleCheckboxChange = (boma) => {
    const newBomaData = {
      id: boma.id,
      region: boma.region,
      timeCreated: boma.timeCreated,
    };

    if (checkedCollectionList.some((item) => item.id === newBomaData.id)) {
      // If the ID is already in the array, remove it
      dispatch(setCheckedCollectionList(checkedCollectionList.filter((item) => item.id !== newBomaData.id)))
    } else {
      // If the ID is not in the array, add it
      dispatch(setCheckedCollectionList([...checkedCollectionList, newBomaData]));
    }


    // //console.log("CHECKED ITESM ",checkedCollectionList);

    // const filteredBomaData = userBomaData.features.filter(boma =>
      // checkedCollectionList.some(item => item.id === boma.properties.id)
    // );



    // if(checkedCollectionList.length>0){

    // let updatedData = new Object();
    // updatedData.crs = filteredData.crs;
    // updatedData.type = filteredData.type;
    // updatedData.features = filteredBomaData;


    // dispatch(setFilteredData(updatedData));

    // //console.log("FILTERED BOMA DATA",filteredBomaData)


  // }

  // let filteredData = useSelector((state) => state.global.userBomaData);



  // const handleAddressSearch = (e) => {
  //   const searchTerm = e.target.value.toLowerCase();
  //   setAddressSearch(searchTerm);
  //   ////console.log(filteredData)

  //   const filtered = filteredData.features.filter((entry) =>
  //     // ////console.log(earthquakeData);
  //     entry.properties.region.toLowerCase().includes(searchTerm)
  //   );
  //   ////console.log(filtered);
  //   // filteredData.features = filtered;

  //   let updatedData = new Object();
  //   updatedData.crs = filteredData.crs;
  //   updatedData.type = filteredData.type;
  //   updatedData.features = filtered;


  //   dispatch(setFilteredData(updatedData));
  // };






    // //console.log(checkedItems);
  };

  const handleGroupBomaClick = async () => {
    //console.log(checkedCollectionList);
    const groupApiUrl = `${process.env.REACT_APP_AUTH_BASE_URL}collections/group`;

    // Extract IDs of the collections to be grouped
    const collectionsToBeGrouped = checkedCollectionList.map((item) => item.id);
    const accessToken = sessionStorage.getItem("accessToken");

    try {
      // Make the API call using async/await
      const response = await axios.post(
        groupApiUrl,
        {
          collections: collectionsToBeGrouped,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      //console.log("GROUPING RESPONSE ", response);

      const collectionGroupMap = response.data.reduce(
        (acc, collectionDetails) => {
          acc[collectionDetails.collection_id] = collectionDetails.group_id; // Replace 'groupId' with the correct key from your API
          return acc;
        },
        {}
      );

      // Assuming you're using Redux to update the store
      dispatch(setBomaGroupIdDetails(collectionGroupMap));

      //update the groupid for all the bomas

      // Assuming the response contains meaningful data
      toast.success(
        `Group created successfully: ${response.data.message || "Success"}`
      );
    } catch (error) {
      console.error(error);
      toast.error(
        `Error creating group: ${
          error.response?.data?.message || "Unexpected error occurred"
        }`
      );
    } finally {
      // Clear the checked items regardless of success or failure

      dispatch(setCheckedCollectionList([]));
      setIsBomaGroupModelOpen(false);
      //close the side menu
      setOpenMenuId(false);
    }
  };

  const handleUngroupClick = async () => {
    const groupApiUrl = `${process.env.REACT_APP_AUTH_BASE_URL}collections/ungroup`;

    const accessToken = sessionStorage.getItem("accessToken");

    try {
      // Make the API call using async/await
      const response = await axios.post(
        groupApiUrl,
        {
          collections: [collectionIdToUngroup],
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      //console.log("UNGROUPING RESPONSE ", response);

      // Assuming you're using Redux to update the store
      dispatch(removeFromGroupId(collectionIdToUngroup));

      //update the groupid for all the bomas

      // Assuming the response contains meaningful data
      toast.success(`Ungrouped successfully`);
    } catch (error) {
      console.error(error);
      toast.error(
        `Error removing from group: ${
          error.response?.data?.message || "Unexpected error occurred"
        }`
      );
    } finally {
      // Clear the checked items regardless of success or failure

      // setCheckedItems([]);
      // setIsBomaGroupModelOpen(false);
      //close the side menu
      setOpenMenuId(false);
      setIsUngroupModelOpen(false);
    }

    setOpenMenuId(null); // Close the menu
  };


  return (
    <div className="space-y-1 rounded-md shadow-md max-w-md mx-auto">

      {availableBomaDataLength === 0 ? (
        <p className="p-4">No boma data available</p>
      ) : (
        groupedDataByDate.map(({ date, features }) => (
          <div key={date}>
            {/* Date Header */}
            <div
              className="flex items-center justify-between cursor-pointer p-3 bg-[#585FFF66]"
              onClick={() => {
                const dateIdx = expandedDate.findIndex((item) => item === date);

                if (dateIdx === -1) {
                  // If the date does not exist in the array, add it
                  setExpandedDate([...expandedDate, date]);
                } else {
                  // If the date already exists, remove it
                  setExpandedDate(expandedDate.filter((item) => item !== date));
                }
              }}
            >
              <div className="flex items-center space-x-2 px-3">
                <p className="text-gray-300 text-sm font-medium">{date}</p>
              </div>
              <ChevronRightIcon
                className={`w-4 h-4 text-gray-400 transform transition-transform ${
                  expandedDate === date ? "rotate-90" : ""
                }`}
              />
            </div>

            {/* Expanded Date Group - List of Entries for this date */}
            {expandedDate.includes(date) && (
              <div>
                {features.map((entry) => {
                  // Determine status using dictionary
                  const entryStatus =
                    jobStatusList[entry.properties.id] || "not-started";

                  return (
                    <div
                      key={entry.properties.id}
                      className={`transition-all duration-200 p-3 border border-[#3A3A6A] bg-[#1B1B2F]
                        ${
                          clickedCollectionId === entry.properties.id
                            ? "bg-[#2D2D44]"
                            : ""
                        }`}
                    >
                      <div className="flex items-center justify-between">
                        {/* Left Side: Checkbox */}
                        <div className="flex items-center space-x-2">
                          <input
                            type="checkbox"
                            className="form-checkbox text-[#A3A3FF] rounded-sm bg-[#2A2A40] border-[#3A3A6A]"
                            checked={checkedCollectionList.some(
                              (item) => item.id === entry.properties.id
                            )}
                            onChange={() =>
                              handleCheckboxChange(entry.properties)
                            }
                          />
                        </div>

                        {/* Status Tag */}
                        {isLoggedIn && (
                          <div className="flex justify-center space-x-2">
                            <span
                              className={`px-4 py-1 text-xs font-semibold rounded-lg ${
                                entryStatus === "completed"
                                  ? "bg-green-600 text-white"
                                  : entryStatus === "not-started"
                                  ? "bg-gray-500 text-white"
                                  : entryStatus === "in-progress"
                                  ? "bg-yellow-500 text-black"
                                  : "bg-blue-500 text-white"
                              }`}
                            >
                              {entryStatus === "completed"
                                ? "Completed"
                                : entryStatus === "pending"
                                ? "Pending"
                                : entryStatus === "in-progress"
                                ? "In Progress"
                                : "Not Started"}
                            </span>
                          </div>
                        )}
                        {/* Right Side: 3-dot Menu */}
                        {isLoggedIn && (
                          <div className="relative">
                            <DotsVerticalIcon
                              className="w-5 h-5 text-gray-400 cursor-pointer"
                              onClick={() =>
                                setOpenMenuId(
                                  openMenuId === entry.properties.id
                                    ? null
                                    : entry.properties.id
                                )
                              }
                            />
                            {openMenuId === entry.properties.id && (
                              <div className="absolute right-0 mt-2 w-32 bg-[#2A2A40] border border-[#3A3A6A] rounded-sm shadow-lg z-50">
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-300 hover:bg-[#585FFF66]"
                                  onClick={() => handleAddImages(openMenuId)}
                                  disabled={isUploading}
                                >
                                  Add Images
                                </button>

                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-300 hover:bg-blue-700"
                                  onClick={() => handleAnalyzeClick(openMenuId)}
                                >
                                  Analyze
                                </button>

                                <button
                                  className={`block w-full px-4 py-2 text-left text-sm text-gray-300  ${
                                    checkedCollectionList.length > 1
                                      ? "cursor-pointer hover:bg-blue-500"
                                      : "hover:bg-gray-500 cursor-not-allowed"
                                  } `}
                                  disabled={
                                    checkedCollectionList.length > 1 ? false : true
                                  }
                                  onClick={() => setIsBomaGroupModelOpen(true)}
                                >
                                  Group
                                </button>

                                <button
                                  className={`block w-full px-4 py-2 text-left text-sm text-gray-300 hover:bg-red-500 disabled:hover:bg-gray-500 disabled:cursor-not-allowed`}
                                  disabled={
                                    !bomaGroupIdDetails.hasOwnProperty(
                                      openMenuId
                                    )
                                  }
                                  onClick={() => {
                                    setCollectionIdToUngroup(openMenuId);
                                    setIsUngroupModelOpen(true);
                                  }}
                                >
                                  Ungroup
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-left text-sm text-gray-300 hover:bg-red-500"
                                  onClick={() => handleDeleteClick(openMenuId)}
                                >
                                  Delete
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      {/* Automatically Expanded Content for the Entry */}
                      <div
                        onClick={() => handleSideMenuClick(features, entry)}
                        className={`mt-3 pl-6 space-y-1 rounded-md cursor-pointer`}
                      >
                        <div className="p-1">
                          <p className="text-gray-400 text-sm">
                            <strong className="text-gray-300">
                              Total cattle:
                            </strong>{" "}
                            {bomaCattleCount &&
                            bomaCattleCount.hasOwnProperty(entry.properties.id)
                              ? bomaCattleCount[entry.properties.id]
                              : `N/A`}
                          </p>
                          <p className="text-gray-400 text-sm">
                            <strong className="text-gray-300">Region:</strong>{" "}
                            {entry.properties.region}
                          </p>

                          <div className="py-1 "></div>

                          <p className="text-gray-400 text-sm">
                            <strong className="text-gray-300">
                              Collection ID:
                            </strong>{" "}
                            {entry.properties.id}
                          </p>

                          <p className="text-gray-400 text-sm">
                            <strong className="text-gray-300">Group ID:</strong>{" "}
                            {bomaGroupIdDetails?.[entry.properties?.id] ||
                              "N/A"}
                          </p>

                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ))
      )}

      <BomaGroupModel
        isOpen={isBomaGroupModelOpen}
        bomaList={checkedCollectionList}
        handleRemove={(id) => {
          dispatch(setCheckedCollectionList(checkedCollectionList.filter((item) => item !== id)));
          //console.log("ITEM REMOVED");
        }}
        handleCreateGroup={handleGroupBomaClick}
        onClose={() => {
          setIsBomaGroupModelOpen(false);
          dispatch(setCheckedCollectionList([]));
        }}
      />

      <UngroupCollectionModel
        isOpen={isUngroupModelOpen}
        collection_id={collectionIdToUngroup}
        handleCancelButton={() => setIsUngroupModelOpen(false)}
        handleUngroupClick={handleUngroupClick}
      />
    </div>
  );
};
export default EarthquakeList;
