import SearchBar from "./SearchBar";
import DateRangeFilter from "./DateRangeFilter";
import EarthquakeList from "./EarthquakeList";
import { useSelector } from "react-redux";

import ReviewDetails from "./ReviewDetails";
import { useState } from "react";

import ReportList from "./ReportList";

import collectionImg from "../../assets/icons/collectionImg.png"
import reportImg from "../../assets/icons/reportImg.png"

import eyeClimateLogo from "../../assets/icons/eyeclimate_logo.png"

const Sidebar = () => {
  const isReviewing = useSelector((state) => state.global.isReviewing);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const [showReports, setShowReports] = useState(false);
  const [active, setActive] = useState("Collections");

{/* 
    {
      isLoggedIn &&
     <div className="flex pt-6 ml-4 text-lg ">
      <div
        className={`flex items-center mr-6 cursor-pointer ${
          active === "Collections" ? "text-purple-400" : "text-gray-400"
        }`}
        onClick={() => setActive("Collections")}
      >
        <img src={collectionImg} alt="collection img" className="h-4 mr-2" />
        <span>Collections</span>
      </div>
      <div
        className={`flex items-center cursor-pointer ${
          active === "Reports" ? "text-purple-400" : "text-gray-400"
        }`}
        onClick={() => setActive("Reports")}
      >
        <img src={reportImg} alt="collection img" className="h-4 mr-2" />

        <span >Reports</span>
      </div>
    </div>
}

      {
        active === "Reports" ? (
          <ReportList />
        ) : (
          <>
          {isReviewing ? (
            <ReviewDetails />
          ) : (
            <>
              {isLoggedIn ? (
                <></>
              ) : (
                <div className="p-3">
                  <h1 className="text-2xl font-bold mb-4">EyeClimate Inc</h1>
                  <p className="text-xl mb-2">Public Collections</p>
                </div>
              )}
    
              <div className="p-3">
                <p className="mb-2 ml-1 text-md">Search Collections</p>
                <SearchBar />
                <DateRangeFilter />
              </div>

              <EarthquakeList />
            </>
          )}
          </>

        )
      } */}

      return (
        <div
          className="text-white w-72 h-full overflow-y-auto bg-[#1E1E20]"
        >

      {isReviewing ? (
        <ReviewDetails />
      ) : (
        <>

          {isLoggedIn ? (
            <div className="p-1">
              {/* <img src={eyeClimateLogo} className="w-8 h-8 mr-3" alt="" srcset="" /> */}
            <h1 className="text-2xl font-bold mb-4 ml-12">EyeClimate Inc</h1>
          </div>
          ) : (
            <div className="">
              <div className="flex pt-2">
                
              {/* <img src={eyeClimateLogo} className="w-8 h-8 mr-3" alt="" srcset="" /> */}
              <h1 className="text-2xl font-bold mb-4 ml-12">EyeClimate Inc</h1>
              </div>

              <p className="text-xl mb-2 p-3">Public Collections</p>
            </div>
          )}

          <div className="p-3">
            <p className="mb-2">Search Collections</p>
            <SearchBar />
            <DateRangeFilter />
          </div>
          <EarthquakeList />
        </>
      )}
    </div>
  );
};

export default Sidebar;
