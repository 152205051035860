import React, { useState } from "react";
import {useNavigate } from "react-router-dom";
import Button from "../components/authComponents/Button.jsx";
import Input from "../components/authComponents/Input.jsx";
// import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
// import authService from "../utils/auth.js";
// import { toast } from "react-toastify";
// import { login as authLogin } from "../store/slices/authSlice.js";

import userIcon from "../assets/icons/username.png";
// import lockIcon from "../assets/icons/lock.png";
import axios from "axios";
import { toast } from "react-toastify";

function ForgotPassword() {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [error, setError] = useState("");
  const BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;

  const handleForgotPassword = async (data) => {
    setError("");

    if(!data) {
      setError("Please enter email");
      return;
    }

    try {

      ////console.log(data)


      const queryParam = new URLSearchParams({
        email: data.email
      });

      const response = await axios.get(
        `${BASE_URL}auth/forgotpass?${queryParam}`
      );

      ////console.log("response", response);
      // if (response.status == 200) {
        toast.success("Kindly check your email for instructions");
        //   dispatch(authLogin(userData));
        navigate("/");
      // } else {
        // setError("Failed, please try again later");
      // }



    } catch (error) {
      ////console.log(error)
      setError(error.message);
    }
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen"
      style={{ background: "#1E1E20" }} 
    >
      <div
        className="w-full max-w-md bg-gray-800 p-10 rounded-xl  transform "
        style={{ background: "#1E1E20" }}
      >
        <h2 className="text-center text-3xl font-bold text-gray-200 mb-6">
          EyeClimate Inc
        </h2>
        <h3 className="text-center text-2xl text-gray-200 mb-6">Log in</h3>

        {error && <p className="text-red-500 text-center mb-6">{error}</p>}

        <form onSubmit={handleSubmit(handleForgotPassword)} className="space-y-6">
          <div className="space-y-5">
            <div className="space-y-1">
              <label className="block text-gray-300">Email</label>
              <div className="relative">
                <img
                  src={userIcon} 
                  alt="user icon"
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5"
                />
                <Input
                  placeholder="Email"
                  type="text"
                  {...register("email", { required: true })}
                  className="block w-full p-3 pl-12 bg-black text-white border border-gray-600 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:bg-gray-700 focus:border-transparent"
                />
              </div>
            </div>



            <div className="border-b border-b-gray-500"></div>


            <Button
              type="submit"
              onClick={handleForgotPassword}
              className="w-full py-2 px-6 font-bold text-gray-100 bg-indigo-600 rounded-xl hover:bg-indigo-700 transition-all duration-300 shadow-md hover:shadow-lg focus:ring-2 focus:ring-indigo-500"
            >
              Send recovery email
            </Button>

            <button
              onClick={() => navigate("/login")}
              className="w-full py-2 font-bold border-2 rounded-xl hover:bg-blue-100 transition duration-300"
              style={{ color: "#7E82ED", borderColor: "#7E82ED" }}
            >
              Go Back
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
