import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setFilteredData } from "../../store/slices/globalSlice";
import searchIcon from "../../assets/icons/searchIcon.png";

const SearchBar = () => {
  const dispatch = useDispatch();
  const [addressSearch, setAddressSearch] = useState("");
  const filteredData = useSelector((state) => state.global.userBomaData);

  const handleAddressSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setAddressSearch(searchTerm);

    const filtered = filteredData.features.filter((entry) =>
      entry.properties.region.toLowerCase().includes(searchTerm)
    );

    const updatedData = {};
    updatedData.crs = filteredData.crs;
    updatedData.type = filteredData.type;
    updatedData.features = filtered;

    dispatch(setFilteredData(updatedData));
  };

  return (
    <div className="flex items-center bg-black rounded-md mb-4">
      <input
        type="text"
        placeholder="Search by address"
        className="w-full bg-black text-gray-400 p-2 pl-4 rounded outline-none"
        value={addressSearch}
        onChange={handleAddressSearch}
      />
      <img src={searchIcon} alt="search" className="w-5 mr-2" />
    </div>
  );
};

export default SearchBar;
