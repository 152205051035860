import React from "react";
import { XIcon, DownloadIcon } from "@heroicons/react/solid";

const MosaicImageViewModal = ({
  isOpen,
  onClose,
  imageUrl,
  isLoading,
  bomaId,
}) => {
  if (!isOpen) return null;
  //console.log(imageUrl)

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 transition-opacity duration-300 ease-in-out">
      <div className="bg-[#39393E] rounded-md shadow-lg w-11/12 sm:w-3/4 max-w-3xl relative transform transition-transform duration-300 ease-in-out scale-100">
        {/* Loader */}
        {isLoading ? (
          <div className="flex justify-center items-center mt-4 h-60">
            {/* need download button */}

            <button
              onClick={onClose}
              className="absolute top-3 right-3 text-gray-800 hover:text-gray-900 transition-colors duration-200 ease-in-out"
            >
              <XIcon className="w-6 h-6 bg-white hover:bg-blue-300" />
            </button>

            <div className="loader h-12 w-12 border-4 border-gray-300 border-t-4 border-t-blue-500 rounded-full animate-spin"></div>
          </div>
        ) : (
          <>
            <a
              // onClick={onMosaicImageDownloadClick}
              className="absolute top-3 right-12 text-gray-800 hover:text-gray-900 transition-colors duration-200 ease-in-out"
              href={imageUrl}
              download={`Mosaic_Image_${bomaId}`}
            >
              <DownloadIcon className="w-6 h-6 bg-white hover:bg-blue-300" />
            </a>

            {/* Close button */}
            <button
              onClick={onClose}
              className="absolute top-3 right-3 text-gray-800 hover:text-gray-900 transition-colors duration-200 ease-in-out"
            >
              <XIcon className="w-6 h-6 bg-white hover:bg-blue-300" />
            </button>

            {/* Image */}
            <div className="flex justify-center items-center h-[80vh] w-full max-h-[80vh] overflow-hidden">
              <img
                src={imageUrl}
                alt="Mosaic view"
                className="h-full max-h-full w-auto object-contain rounded-md p-1.5"
              />
            </div>

            {/* Image Details */}
            <h5 className="text-center">
              MOSAIC IMAGE{" "}
              <span className="font-bold">(Collection ID: {bomaId})</span>
            </h5>
          </>
        )}
      </div>
    </div>
  );
};

export default MosaicImageViewModal;
