import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import WaitingScreen from "./pages/WaitingScreen.jsx";
import "react-toastify/dist/ReactToastify.css";
import { convertDataToGeoJSON } from "./utils/convertToGeojson.js";
import {
  setFilteredData,
  setUserBomaData,
  setBomaCattleCount,
  setJobStatusList,
  setAnalysisJobList,
  setBomaGroupIdDetails,
} from "./store/slices/globalSlice.js";
import { useDispatch, useSelector } from "react-redux";

import useOnlineStatus from "./hooks/useOnlineStatus.js";
import NoInternet from "./components/modals/NoInternet.jsx";
import AppRoutes from "./routes.js";
import DeleteBomaModel from "./components/modals/DeleteBomaModal.jsx";

function App() {
  const dispatch = useDispatch();
  const isOnline = useOnlineStatus();

  const [loading, setLoading] = useState(true);

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const showDeleteBomaModal = useSelector((state) => state.global.showDeleteBomaModal);

  //reset state of boma created and boma deleted
  const bomaCreated = useSelector((state) => state.upload.bomaCreated);
  const bomaDeleted = useSelector((state) => state.global.bomaDeleted);

  const BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;


  useEffect(() => {
    const fetchDataAndUpdate = async () => {
      if (!isOnline) return;
      try {
        const accessToken = sessionStorage.getItem("accessToken");
        const jobStatusList = {};

        let incomingGeoJSON = null;

        if (isLoggedIn) {
          const response = await fetch(`${BASE_URL}collections`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          });

          const data = await response.json();
          // console.log("COLLECTION DATA", data);

          const collectionGroupMap = data.reduce((acc, collectionDetails) => {
            if (collectionDetails.groups) {
              acc[collectionDetails.id] = collectionDetails.groups[0]; 
            }
            return acc;
          }, {});

          // console.log(collectionGroupMap)

          dispatch(setBomaGroupIdDetails(collectionGroupMap));

          incomingGeoJSON = convertDataToGeoJSON(data);

          incomingGeoJSON.features.forEach(
            (item) => (item.properties.region = item.properties.region[0])
          );

          incomingGeoJSON.features = incomingGeoJSON.features.filter(
            (item) => item.geometry.coordinates[0] != null
          );

          dispatch(setUserBomaData(incomingGeoJSON));
          dispatch(setFilteredData(incomingGeoJSON));
          setLoading(false);
        } else {
          const response = await fetch(`${BASE_URL}collections/public`);
          const data = await response.json();
          data.pop();

          incomingGeoJSON = convertDataToGeoJSON(data);

          incomingGeoJSON.features.forEach(
            (item) => (item.properties.region = item.properties.region[0])
          );
          dispatch(setUserBomaData(incomingGeoJSON));
          dispatch(setFilteredData(incomingGeoJSON));
          setLoading(false);
        }

        if (!isLoggedIn) return;

        // Update cattle count
        let bomaCattleCount = {};
        const fetchPromises = incomingGeoJSON.features.map(async (item) => {
          try {
            const response = await fetch(
              `${BASE_URL}analytics/results/collections/${item.properties.id}`,
              {
                method: "GET",
                headers: { Authorization: `Bearer ${accessToken}` },
              }
            );
            const data = await response.json();
            bomaCattleCount[item.properties.id] = data[0].result.count;
            jobStatusList[item.properties.id] = "completed";
          } catch (error) {
            //console.log(`Failed to fetch data for ${item.properties.id}:`,error);
          }
        });

        await Promise.all(fetchPromises);
        dispatch(setBomaCattleCount(bomaCattleCount));

        try {
          const response = await fetch(`${BASE_URL}analytics/collections`, {
            method: "GET",
            headers: { Authorization: `Bearer ${accessToken}` },
          });
          const data = await response.json();

          data.map((item) => {
            const bomaId = item.id;
            jobStatusList[bomaId] = "in-progress";
            dispatch(setAnalysisJobList(bomaId));
          });
        } catch (error) {
          //console.log(`Failed to fetch job status:`, error);
        }
        dispatch(setJobStatusList(jobStatusList));
      } catch (error) {
        //console.log("ERROR ", error);
      }
    };
    fetchDataAndUpdate();
  }, [
    BASE_URL,
    dispatch,
    isLoggedIn,
    isOnline,
    bomaCreated,
    bomaDeleted,
  ]);

  //remove all the popup if available
  useEffect(() => {
    const popupAvailable = document.getElementsByClassName("mapboxgl-popup");
    if (popupAvailable.length) {
      popupAvailable[0].remove();
    }
  }, [isLoggedIn]);

  if (!isOnline) {
    return <NoInternet show={true} />;
  }

  if (loading) {
    return <WaitingScreen />;
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{ height: "20px" }}
        limit={3}
      />

      {showDeleteBomaModal && <DeleteBomaModel />}

      <AppRoutes />
    </div>
  );
}

export default App;
