import React from "react";
import { MenuIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import { setIsSidebarOpen } from "../../store/slices/globalSlice";
import Sidebar from "./Sidebar";
import eyeClimateLogo from "../../assets/icons/eyeclimate_logo.png";

const SideMenu = () => {
  const isSidebarOpen = useSelector((state) => state.global.isSidebarOpen);
  const dispatch = useDispatch();
  const isReviewing = useSelector((state) => state.global.isReviewing)
  const toggleMenu = () => dispatch(setIsSidebarOpen(!isSidebarOpen));

  return (
    <div className="fixed top-0 left-0 h-full flex z-10">
      {/* Sidebar */}
      {isSidebarOpen && <Sidebar />}

      {/* Fixed Logo */}
      {
        !isReviewing && 
      <img
      src={eyeClimateLogo}
      onClick={()=> window.location.reload()}
      className="h-8 w-8 fixed top-2 left-2 z-20 cursor-pointer"
      alt="EyeClimate Logo"
      />
    }

      {/* Menu Button */}
      <button
        onClick={toggleMenu}
        className={`absolute top-0 p-2 z-10 bg-gray-800 text-white rounded-r-sm hover:bg-gray-700 focus:outline-none transition-all duration-300 ease-in-out ${
          isSidebarOpen ? "left-72" : "left-14"
        }`}
        >
        <MenuIcon className="w-6 h-6" />
      </button>
    </div>
  );
};

export default SideMenu;
