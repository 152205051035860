import { jwtDecode } from "jwt-decode";
import { login } from "../store/slices/authSlice.js";

// Function to check if the token is valid
const isTokenValid = (token) => {
  if(!token) return false;
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Get current time in seconds
    return decodedToken.exp > currentTime; // Return true if the token has not expired
  } catch (error) {
    //console.log("Invalid token", error);
    return false;
  }
};

//refresh access token
const refreshAccessToken = async (dispatch) => {
  const refreshToken = sessionStorage.getItem("refreshToken"); // Get refresh token from sessionStorage

  if (!refreshToken) {
    //console.log("No refresh token found");
    return;
  }

  const BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;
  try {
    const response = await fetch(
      `${BASE_URL}auth/refresh`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", 
        body: JSON.stringify({
          refresh_token: refreshToken, 
        }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      sessionStorage.setItem("accessToken", data.access_token); 
      sessionStorage.setItem("refreshToken", data.refresh_token); 

      dispatch(login("User"));
    } else {
      sessionStorage.removeItem("accessToken"); 
      sessionStorage.removeItem("refreshToken"); 
    }
  } catch (error) {
    //console.log("Failed to refresh access token:", error);
  }
};

// Function to auto-login the user if access token exists and is valid
const autoLogin = async (dispatch, navigate) => {
  const token = sessionStorage.getItem("accessToken");

  if (token && isTokenValid(token)) {
    // Token exists and is valid, dispatch login action
    dispatch(login("autoLogin"));
    navigate("/");
  } else if (token) {
    // Token is expired or invalid, attempt to refresh it
    await refreshAccessToken(dispatch);
  } else {
    //console.log("No token found. User must log in.");
  }
};

export default autoLogin;
