import { Routes, Route, Navigate } from 'react-router-dom';

import Home from "./pages/Home.jsx"
import Login from "./pages/Login.jsx";
import Signup from './pages/Signup.jsx';
import ProtectedRoute from './components/ProtectedRoute.jsx';
import LoadingScreen from './pages/LoadingScreen.jsx';
import ForgotPassword from './pages/ForgotPassword.jsx';
import UpdatePassword from "./pages/UpdatePassword.jsx";
import ActivationPage from './pages/ActivationPage.jsx';

const AppRoutes = () => {
    return (
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/app"
          element={
            <ProtectedRoute>
              <LoadingScreen />
            </ProtectedRoute>
          }
        />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/forgotpass" element={<UpdatePassword/>}/> 
        <Route path="/activate" element={<ActivationPage/>} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    )
}

export default AppRoutes;