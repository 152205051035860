import React from "react";
import eyeClimateLogo from "../assets/icons/eyeclimate_logo.png";

function LoadingScreen() {
  return (
    <div
      className="flex items-center justify-center h-screen text-white"
      style={{ background: "#1E1E20" }}
    >
      <div className="relative flex flex-col items-center space-y-4">
        {/* Image */}
        <div className="relative">
          <img
            src={eyeClimateLogo} // Replace with your image URL
            alt="Loading"
            className="w-32 h-32 object-cover rounded-full p-4"
          />
          {/* Rotating Circle */}
          <div className="absolute inset-0 border-4 border-t-4 border-t-green-500 border-gray-200 rounded-full animate-spin"></div>
        </div>
        {/* Loading Message */}
        <p className="text-lg font-semibold">
          We are getting things ready for you...
        </p>
      </div>
    </div>
  );
}

export default LoadingScreen;
