import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const ActivationPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;

  const url = new URL(window.location.href);
  // console.log("URL ", url);

  useEffect(() => {
      async function activateAccount() {
        const tokenParam = url.searchParams.get("token");
        if (!tokenParam) {
          toast.error("Activation failed !")
          // console.log("No token param is there");
          navigate("/");
        } else {
          // console.log("TOKEN ", tokenParam);
          setIsLoading(true);
          try {
            //make api call
            await axios.get(`${BASE_URL}auth/validate/${tokenParam}`);
            // console.log(response);
    
            toast.success("Account activated successfully !");
            navigate("/login");
          } catch (error) {
            toast.error("Error activating account !");
            // console.log("error activating account ", error);
            navigate("/")
        } finally {
            setIsLoading(false);
          }
        }        
      }

      activateAccount();
  }, []);

  return (
    <div
      className="flex items-center justify-center min-h-screen"
      style={{ background: "#1E1E20" }}
    >
      <div
        className="w-full max-w-md bg-gray-800 p-10 rounded-xl transform"
        style={{ background: "#1E1E20" }}
      >
        <h2 className="text-center text-3xl font-bold text-gray-200 mb-6">
          EyeClimate Inc
        </h2>
        <h3 className="text-center text-2xl  text-gray-200 mb-6">
          Account activation in progress
        </h3>

        {isLoading ? (
          <div className="flex justify-center items-center">
            <div className="w-10 h-10 border-4 border-t-4 border-t-blue-500 border-gray-200 rounded-full animate-spin"></div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ActivationPage;
