// export const processTimelineData = (data) => {
//     // Group by date and pick the entry with the latest timestamp for each date
//     const latestByDate = {};
  
//     data.forEach((item) => {
//       const date = new Date(item.time_updated).toISOString().split("T")[0]; // Extract date only
//       const currentEntry = latestByDate[date];
  
//       // Update entry if:
//       // - No entry for the date exists yet
//       // - Or this entry has a more recent `time_updated`
//       if (
//         !currentEntry ||
//         new Date(item.time_updated) > new Date(currentEntry.time_updated)
//       ) {
//         latestByDate[date] = item;
//       }
//     });
  
//     // Convert filtered entries into sorted arrays for x-axis and series data
//     const dates = Object.keys(latestByDate).sort(
//       (a, b) => new Date(a) - new Date(b)
//     );
//     const seriesData = dates.map((date) => ({
//       y: latestByDate[date].result.count,
//       collection_id: latestByDate[date].collection_id,
//       date,
//     }));
  
//     return { dates, seriesData };
//   };


export const processTimelineData = (data) => {
  // Sort all entries by date (ascending order)
  const sortedData = data.sort(
    (a, b) => new Date(a.time_updated) - new Date(b.time_updated)
  );

  // Convert sorted entries into arrays for x-axis and series data
  const dates = sortedData.map((item) =>
    new Date(item.time_updated).toISOString().split("T")[0]
  );
  const seriesData = sortedData.map((item) => ({
    y: item.result.count,
    collection_id: item.collection_id,
    date: new Date(item.time_updated).toISOString().split("T")[0],
  }));

  return { dates, seriesData };
};
