import React from "react";
import { XIcon } from "@heroicons/react/solid";
import { processTimelineData } from "../../utils/graph";


import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from "chart.js";


// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);



const TimeLineGraphModel = ({
  data: incomingTimelineData,
  isOpen,
  onClose,
  highlightCollectionId,
}) => {
  const { dates, seriesData } = processTimelineData(incomingTimelineData);


  // Transform data for chart
  const labels = seriesData.map(item => item.date); // Date for x-axis (strings)
  const dataPoints = seriesData.map(item => parseFloat(item.y)); // Convert 'y' to number
  const collectionIds = seriesData.map(item => item.collection_id); // Store collection IDs

  // Highlight specific point based on `highlightCollectionId` prop
  const highlightIndex = collectionIds.indexOf(highlightCollectionId);
  const highlightPoint = highlightIndex !== -1 ? {
    x: seriesData[highlightIndex].date,
    y: seriesData[highlightIndex].y,
    collection_id: seriesData[highlightIndex].collection_id,
  } : null;

  // Chart.js data structure
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Cattle Count",
        data: dataPoints,
        fill: false,
        borderColor: 'rgba(67, 61, 239, 0.6)',
        tension: 0.1,
        pointRadius: (context) => {
          // If the current point matches the highlight, return larger radius for emphasis
          return highlightPoint && context.dataIndex === highlightIndex ? 8 : 8;
        },
        pointBackgroundColor: (context) => {
          // Highlight the point with a different color
          return highlightPoint && context.dataIndex === highlightIndex ? "rgba(255, 99, 132, 0.6)" : "rgba(191, 235, 90, 0.94)";
        },
        // pointBackgroundColor: 'rgba(255, 99, 132, 0.6)', // Add point color
        pointBorderWidth: 1, // Adjust border width
        hoverRadius: 12, // Increase radius on hover
        hoverBorderWidth: 2, // Increase border width on hover
      },
    ],
  };


  // Options to customize tooltip and x-axis
  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'category', // Use category scale to display string labels on x-axis
        title: {
          display: true,
          // text: 'Date',
        },
        ticks: {
          color: '#ffffff', // Set text color for x-axis ticks
          font: {
            size: 14, // Set font size for x-axis ticks
          },
        },
        
      },
      y: {
        title: {
          display: true,
          text: 'Count',
          color: '#ffffff', 
          font: {
            size: 12, // Set font size for the label
            weight: 'bold', // Make the label bold
            family: 'Arial', // Specify font family
          },
        },
        ticks: {
          color: '#ffffff', // Set text color for y-axis ticks
          font: {
            size: 14, // Set font size for y-axis ticks
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataPoint = seriesData[context.dataIndex];
            // Custom HTML formatted as plain text with line breaks
            return [
              `Date: ${dataPoint.date}`,
              `Collection ID: ${dataPoint.collection_id}`,
              `Count: ${context.raw}`
            ];
          },
        },
      },
      legend: {
        labels: {
          font: {
            size: 14, // Adjust label font size
            weight: "bold", // Make the label bold
            family: "Arial", // Specify font family
          },
          color: "#ffffff", // Change label color
        },
      },
    },
    
    
    
  };


  if (!isOpen) return null;

  return (
<div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 transition-opacity duration-300 ease-in-out">
  <div
    className="bg-[#39393E] rounded-md shadow-lg p-2 relative transform transition-transform duration-300 ease-in-out scale-100"
    style={{ width: '60vw', height: '60vh' }}
  >
    <>
      {/* Close button */}
      <button
        onClick={onClose}
        className="absolute z-10 top-3 right-3 text-gray-800 hover:text-gray-900 transition-colors duration-200 ease-in-out"
      >
        <XIcon className="w-6 h-6 bg-white hover:bg-blue-300" />
      </button>

      {/* Image */}
      <div className="flex justify-center items-center w-full h-full overflow-hidden">
        <Line data={data} options={options} />
      </div>
    </>
  </div>
</div>

  );
};

export default TimeLineGraphModel;
