import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import cattleImg from "../assets/icons/cattle.png";

import globeImg from "../assets/icons/globe.png"
import satelliteImg from "../assets/icons/satellite.png"
import methaneImg from "../assets/icons/methane.png"


const LoadingScreen = () => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (loading) {
      timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(timer);
            navigate("/"); // Navigate to the cattle count page
            return 100;
          }
          return prevProgress + 100 / 30; // Increment to complete in 3 seconds
        });
      }, 35);
    }
    return () => clearInterval(timer);
  }, [loading, navigate]);

  const handleCattleCount = () => {
    setLoading(true);
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen"
      style={{ background: "#1E1E20" }}
    >
      <div className="w-full max-w-md text-white p-10 flex flex-col items-center justify-center">
        {/* Title */}
        <h1 className="text-3xl font-bold mb-4 text-center">Eyeclimate</h1>

        {/* Subtitle */}
        <h3 className="text-2xl mb-4 text-center">Select App</h3>

        {/* Image */}
        <img src={cattleImg} alt="logo" className="h-24 mb-2" />

        {/* Progress Bar */}
        {loading && (
          <div className="w-full bg-gray-700 rounded-full h-1 mt-1 mb-6 overflow-hidden">
            <div
              className="bg-green-500 h-1 rounded-full"
              style={{
                width: `${progress}%`,
              }}
            ></div>
          </div>
        )}



        {/* Buttons */}
        <div className="space-y-2 w-full max-w-sm items-start justify-start ">

        <div className="border-b border-b-gray-500 mb-4"></div>

        {/* Description */}
        <p className="mb-4 pb-4 text-lg text-center">Applications we are working on <br /> stay tuned!</p>

          <button
            onClick={handleCattleCount}
            className="w-full py-2 px-4  bg-black text-gray-200 border border-gray-600 rounded-md shadow-sm placeholder-gray-500 flex "
          >
           <img src={cattleImg} alt="Earth"className="w-6"/>
           <p className="pl-4">Cattle count</p>
          </button>


          <button
            className="w-full py-2 px-4  bg-black text-gray-200 border border-gray-600 rounded-md shadow-sm placeholder-gray-500 flex disabled:cursor-not-allowed disabled:hover:bg-gray-500"
            disabled={true}

          >
           <img src={globeImg} alt="Earth"className="w-6"/>
            <p className="pl-4">Deforestation app </p>
          </button>
          <button
            className="w-full py-2 px-4  bg-black text-gray-200 border border-gray-600 rounded-md shadow-sm placeholder-gray-500 flex disabled:cursor-not-allowed disabled:hover:bg-gray-500"
            disabled={true}

          >
           <img src={satelliteImg} alt="Satellite"  className="w-6"/>
           <p className="pl-4">Satellite app </p>

          </button>
          <button
            className="w-full py-2 px-4  bg-black text-gray-200 border border-gray-600 rounded-md shadow-sm placeholder-gray-500 flex disabled:cursor-not-allowed disabled:hover:bg-gray-500"
            disabled={true}
          >
           <img src={methaneImg} alt="Methane"  className="w-6"/>
           <p className="pl-4">Methane mapper</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
