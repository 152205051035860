import React from "react";
import { XIcon } from "@heroicons/react/solid";

const ImageModal = ({
  isOpen,
  onClose,
  imageUrl,
  isLoading,
  imgName,
  bomaId,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 transition-opacity duration-300 ease-in-out">
      <div className="bg-[#39393E] rounded-md shadow-lg w-11/12 sm:w-3/4 max-w-3xl relative transform transition-transform duration-300 ease-in-out scale-100">
        {/* Loader */}
        {isLoading ? (
          <div className="flex justify-center items-center mt-4 h-60">
            <button
              onClick={onClose}
              className="absolute top-3 right-3 text-gray-800 hover:text-gray-900 transition-colors duration-200 ease-in-out"
            >
              <XIcon className="w-6 h-6 bg-white hover:bg-blue-300" />
            </button>
            <div className="loader h-12 w-12 border-4 border-gray-300 border-t-4 border-t-blue-500 rounded-full animate-spin"></div>
          </div>
        ) : (
          <>
            {/* Close button */}
            <button
              onClick={onClose}
              className="absolute top-3 right-3 text-gray-800 hover:text-gray-900 transition-colors duration-200 ease-in-out"
            >
              <XIcon className="w-6 h-6 bg-white hover:bg-blue-300" />
            </button>

            {/* Image */}
            <img
              src={imageUrl}
              alt="Large View"
              className="w-full h-auto  rounded-md p-1.5 "
            />
            {/* Image Details */}
            <h5 className="text-center">
              {imgName} <span className="font-bold">(Collection ID: {bomaId})</span>
            </h5>
          </>
        )}
      </div>
    </div>
  );
};

export default ImageModal;
