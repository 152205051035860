import React, { useState } from "react";
import { XIcon } from "@heroicons/react/solid";

const MapModal = ({ isOpen, onClose, mapUrl }) => {
  const [loading, setLoading] = useState(true); // Track loading state

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 transition-opacity duration-300 ease-in-out">
      <div
        className="bg-[#39393E] rounded-md shadow-lg relative transform transition-transform duration-300 ease-in-out scale-100"
        style={{ width: "80vw", height: "80vh" }}
      >
        <>
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute top-3 right-3 text-gray-800 hover:text-gray-900 transition-colors duration-200 ease-in-out"
          >
            <XIcon className="w-6 h-6 bg-white hover:bg-blue-300" />
          </button>

          {/* Loading Indicator */}
          {loading && (
            <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 z-10">
            <div className="loader h-12 w-12 border-4 border-gray-300 border-t-4 border-t-blue-500 rounded-full animate-spin"></div>
            </div>
          )}

          {/* Google Maps iframe */}
          <iframe
            src={mapUrl}
            title="Google Maps"
            className="w-full h-full rounded-md"
            allowFullScreen
            loading="lazy"
            onLoad={() => {
              setLoading(false)
              console.log("LOADING OF MAP COMPLETED")
            }
            } // Hide loading indicator on load
          ></iframe>
        </>
      </div>
    </div>
  );
};

export default MapModal;
