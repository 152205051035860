import { useEffect, useState } from "react";
import {
  calculateBounds,
  calculateCenter,
  createCircle,
  extractLatLong,
} from "../../../utils/mapUtils.js";

import { useSelector, useDispatch } from "react-redux";
import {
  setIsReviewing,
  setIsSidebarOpen,
  setImageReviewDetails,
  setHighlightedImageDetails,
  setHighlightedBomaDetails,
  setBomaPopupDetails,
} from "../../../store/slices/globalSlice.js";

const ReviewLayer = ({ mapRef }) => {
  const isReviewing = useSelector((state) => state.global.isReviewing);
  let reviewLayerData = useSelector((state) => state.global.bomaReviewDetails);
  const highlightedImageDetails = useSelector((state) => state.global.highlightedImageDetails);
  const dispatch = useDispatch();


//  const [highlightedImageDetails, setHighlightedImageDetails] = useState(null);

  

  if (typeof reviewLayerData == "string") {
    reviewLayerData = JSON.parse(reviewLayerData);
    reviewLayerData = reviewLayerData.properties.imagesGeojson;

    if (typeof reviewLayerData == "string")
      reviewLayerData = JSON.parse(reviewLayerData);
  }

  let circleCoordinates = null;


  const checkedCollectionList = useSelector((state) => state.mapLayer.checkedCollectionList);


//   dispatch(setHighlightedImageDetails(reviewLayerData?.features[0]));

  try {
    circleCoordinates = extractLatLong(reviewLayerData);
  } catch (error) {
    // ////console.log("ERROR IN EXTRACTING CIRCLE COORDINATES", error);
  }

  if (mapRef.current && mapRef.current.isStyleLoaded()) {
    // ////console.log("Removing layers and sources");

    if (mapRef.current.getLayer("circle-layer")) {
      // ////console.log("Removing circle-layer");
      mapRef.current.removeLayer("circle-layer");
    }

    if (mapRef.current.getSource("circle")) {
      // ////console.log("Removing circle source");
      mapRef.current.removeSource("circle");
    }

    if (mapRef.current.getLayer("cluster-points-layer")) {
      // ////console.log("Removing cluster-points-layer");
      mapRef.current.removeLayer("cluster-points-layer");
    }

    if (mapRef.current.getSource("geojson-points")) {
      // ////console.log("Removing geojson-points source");
      mapRef.current.removeSource("geojson-points");
    }
  } else {
    // ////console.log("Map instance not ready or unloaded; skipping cleanup.");
  }




  //UPDATE THE ID
  const addPointsLayer = (data, highlightDetails) => {

    //console.log("HIGHLIGHTED IMAGE DETAILS ", highlightDetails)


    if (!data || !highlightDetails) return;

    const updatedData = {
      ...data,
      features: data.features.map((feature) => ({
        ...feature,
        properties: {
          ...feature.properties,
          latitude: feature.geometry.coordinates[0],
          longitude: feature.geometry.coordinates[1],
        },
      })),
    };

    //console.log("updated data",updatedData)

    if (mapRef.current) {
      // Add source for geojson points
      if (!mapRef.current.getSource("review-points")) {
        mapRef.current.addSource("review-points", {
          type: "geojson",
          data: updatedData,
        });
      }

      // Add points layer with dynamic styling
      if (!mapRef.current.getLayer("review-layer")) {
        mapRef.current.addLayer({
          id: "review-layer",
          type: "circle",
          source: "review-points",
          paint: {
            "circle-radius": [
              "case",
              [
                "all",
                [
                  "==",
                  ["get", "imageName"],
                  highlightDetails.properties.imageName,
                ]
              ],
              9, // Highlighted point radius
              7, // Default radius
            ],
            "circle-color": [
              "case",
              [
                "all",
                [
                    "==",
                    ["get", "imageName"],
                    highlightDetails.properties.imageName,
                  ]
              ],
              "#C23D60", // Highlighted color
              "#FFFF00", // Default color
            ],
            "circle-opacity": 1,
          },
        });
      }
    }
  };

  useEffect(() => {
    if(checkedCollectionList.length>0) return
    if (isReviewing && mapRef.current && reviewLayerData) {
        //console.log("rendering review layer")
      if (circleCoordinates && circleCoordinates.length > 0) {
        const data = calculateCenter(circleCoordinates);

        const circleCoords = createCircle(data.centerArray, data.radius + 0.1);

        if (mapRef.current.getLayer("review-layer-circle")) {
          mapRef.current.removeLayer("review-layer-circle");
        }
        if (mapRef.current.getSource("circle-review-boundary")) {
          mapRef.current.removeSource("circle-review-boundary");
        }

        mapRef.current.addSource("circle-review-boundary", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: { type: "Polygon", coordinates: [circleCoords] },
          },
        });

        mapRef.current.addLayer({
          id: "review-layer-circle",
          type: "fill",
          source: "circle-review-boundary",
          layout: {},
          paint: {
            "fill-color": "#585FFF",
            "fill-opacity": 0.2,
            "fill-outline-color": "#ffffff",
          },
        });

        reviewLayerData && addPointsLayer(reviewLayerData, highlightedImageDetails);

        const bounds = calculateBounds(circleCoords);
        mapRef.current.fitBounds(bounds, { padding: 40 });

        mapRef.current.on("click", "review-layer", (e) => {
          //console.log("POINT DATA", e.features[0]);

          const features = mapRef.current.queryRenderedFeatures(e.point, {
            layers: ["review-layer"],
          });
          // ////console.log("review layer line 99");

          if (features.length > 0) {
            dispatch(setIsReviewing(true));
            dispatch(setIsSidebarOpen(true));
            const imgDetails = {
              properties: features[0].properties,
              geometry: features[0].geometry,
            };
            //console.log("IMAGE DETAILS ", imgDetails)

            dispatch(setImageReviewDetails(imgDetails));

            const newHighlightedImageDetails = {
                properties : {
                    imageName : features[0].properties.imageName
                }
            }

            dispatch(setHighlightedImageDetails(newHighlightedImageDetails));
            // //console.log("highlited image details ",features[0].geometry)
            dispatch(setHighlightedBomaDetails(null));
          }
        });
      }

      return () => {

        dispatch(setBomaPopupDetails(null));

        if (mapRef.current && mapRef.current.isStyleLoaded()) {
          if (mapRef.current.getLayer("review-layer-circle")) {
            mapRef.current.removeLayer("review-layer-circle");
          }
          if (mapRef.current.getSource("circle-review-boundary")) {
            mapRef.current.removeSource("circle-review-boundary");
          }
          if (mapRef.current.getLayer("review-layer")) {
            mapRef.current.removeLayer("review-layer");
          }
          if (mapRef.current.getSource("review-points")) {
            mapRef.current.removeSource("review-points");
          }
        } else {
          ////console.log("Map instance not ready or unloaded; skipping cleanup.");
        }
      };
    }
  }, [isReviewing, reviewLayerData, dispatch,highlightedImageDetails,circleCoordinates,mapRef, checkedCollectionList]);


  

  return null;
};
export default ReviewLayer;
